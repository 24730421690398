import { useEffect } from "react";
import {
  Button,
  CheckboxGroup,
  ChevronRightIcon,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  StepStatus,
  Text,
  Skeleton,
} from "@allica/ui-react";
import { FormErrorMessage, Select } from "@chakra-ui/react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Checkbox from "src/components/checkbox/Checkbox";
import { FormGroup } from "src/components/input-set/FormGroup";
import { Stage } from "../../BusinessRewards.types";
import { useBusinessRewardsContext } from "../../context/BusinessRewardsContext";
import { FormValues } from "./BusinessRewardsAccountActivity.types";
import {
  generateAccountActivityPayload,
  parseBcaConfiguration,
} from "./BusinessRewardsAccountActivity.utils";
import { DepositsAPI, OnboardingAPI } from "src/core/service";
import { OnboardingConfigurationResponse } from "src/core/service/onboarding-api/OnboardingApi.types";
import { emptyErrorDataHeapId } from "src/core/utils";

export const BusinessRewardsAccountActivity = () => {
  const {
    updateStepperConfig,
    setCurrentStage,
    businessRewardsData,
    setBusinessRewardsData,
    setShowGenericError,
  } = useBusinessRewardsContext();
  const isLoaded = businessRewardsData.questions !== null;

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    getValues,
  } = useForm<FormValues>({
    defaultValues: { ...businessRewardsData?.businessRewardsApplicationSections.accountSection },
  });

  const { status: accountStatus, request: accountRequest } = DepositsAPI(
    `applications/businesses/${businessRewardsData?.applicationID}/account`,
  );

  const {
    response: configurationResponse,
    status: configurationStatus,
    request: configurationRequest,
  } = OnboardingAPI<OnboardingConfigurationResponse>("applications/configuration");

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    setShowGenericError(false);
    const answers = generateAccountActivityPayload(values);
    accountRequest({
      method: "PATCH",
      body: JSON.stringify({ domain: "BRA", answers }),
    });
  };

  useEffect(() => {
    if (accountStatus.success) {
      const newData = { ...businessRewardsData };
      newData.businessRewardsApplicationSections.accountSection = getValues();

      setBusinessRewardsData(newData);
      setCurrentStage(Stage.DOCUMENT);
      updateStepperConfig([
        { stage: Stage.ACCOUNT, value: { status: StepStatus.COMPLETE } },
        {
          stage: Stage.DOCUMENT,
          value: { status: StepStatus.INCOMPLETE },
        },
      ]);
    }
  }, [accountStatus.success]);

  useEffect(() => {
    if (accountStatus.error) setShowGenericError(true);
  }, [accountStatus.error]);

  useEffect(() => {
    if (businessRewardsData.questions === null) configurationRequest();
  }, []);

  useEffect(() => {
    if (configurationStatus.success) {
      setBusinessRewardsData({
        ...businessRewardsData,
        questions: parseBcaConfiguration(configurationResponse.questions),
      });
    }
  }, [configurationStatus.success]);

  useEffect(() => {
    if (configurationStatus.error) {
      throw new Error("error-fetching-bca-configuration");
    }
  }, [configurationStatus.error]);

  return (
    <>
      <Heading size="h1" as="h1" mb="1.6rem">
        Account activity
      </Heading>
      <Text as="p" mb="6.4rem !important" color="neutral.600" textStyle="body-02-regular">
        As part of our ongoing commitment to protecting our Customers money, it is important that we
        know how you expect to use your new account.
      </Text>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Skeleton isLoaded={isLoaded}>
          <FormControl mb="3.2rem" isRequired isInvalid={!!errors.purposeOfAccount}>
            <FormLabel>Purpose of account</FormLabel>
            <FormHelperText mb="1.2rem">
              {businessRewardsData.questions?.PURPOSE_OF_ACCOUNT.description}
            </FormHelperText>
            <Controller
              control={control}
              name="purposeOfAccount"
              rules={{ required: "Please select at least one option" }}
              render={({ field: { ref, ...rest } }) => (
                <CheckboxGroup {...rest}>
                  {businessRewardsData.questions?.PURPOSE_OF_ACCOUNT.options.map(({ id, name }) => {
                    const stringId = String(id);
                    return (
                      <Checkbox
                        ref={ref}
                        key={stringId}
                        value={stringId}
                        data-heapid="purpose-of-account"
                      >
                        {name}
                      </Checkbox>
                    );
                  })}
                </CheckboxGroup>
              )}
            />
            <FormErrorMessage mt="1.2rem" mb="2.4rm" data-heapid="purpose-empty-error">
              <>{errors?.purposeOfAccount && errors?.purposeOfAccount?.message}</>
            </FormErrorMessage>
          </FormControl>
        </Skeleton>
        <Skeleton isLoaded={isLoaded}>
          <FormGroup
            label="Monthly payments in"
            isRequired
            info={businessRewardsData.questions?.MONTHLY_PAYMENTS_IN.description}
            error={errors.monthlyPaymentsIn?.message}
            data-heapid={emptyErrorDataHeapId(errors?.monthlyPaymentsIn?.type, "payments")}
            mb="3.2rem"
          >
            <Select
              placeholder="Please select"
              {...register("monthlyPaymentsIn", {
                required: "Please select an amount",
              })}
              data-heapid="monthly-payments-in"
            >
              {businessRewardsData.questions?.MONTHLY_PAYMENTS_IN.options.map(
                (option, index: number) => (
                  <option key={index} value={option.id}>
                    {option.name}
                  </option>
                ),
              )}
            </Select>
          </FormGroup>
        </Skeleton>
        <Skeleton isLoaded={isLoaded}>
          <FormControl isRequired isInvalid={!!errors.sourceOfFunds}>
            <FormLabel>Source of funds</FormLabel>
            <FormHelperText mb="1.2rem">
              {businessRewardsData.questions?.SOURCE_OF_FUNDS.description}
            </FormHelperText>
            <Controller
              control={control}
              name="sourceOfFunds"
              rules={{ required: "Please select at least one option" }}
              render={({ field: { ref, ...rest } }) => (
                <CheckboxGroup {...rest}>
                  {businessRewardsData.questions?.SOURCE_OF_FUNDS.options.map(({ id, name }) => {
                    const stringId = String(id);
                    return (
                      <Checkbox
                        ref={ref}
                        key={stringId}
                        value={stringId}
                        data-heapid="source-of-funds"
                      >
                        {name}
                      </Checkbox>
                    );
                  })}
                </CheckboxGroup>
              )}
            />
            <FormErrorMessage mt="1.2rem" mb="2.4rm" data-heapid="source-empty-error">
              <>{errors?.sourceOfFunds && errors?.sourceOfFunds?.message}</>
            </FormErrorMessage>
          </FormControl>
        </Skeleton>
        <Button
          isLoading={accountStatus.loading}
          isDisabled={!isLoaded}
          loadingText="Save and continue"
          spinnerPlacement="end"
          type="submit"
          float="right"
          padding="2.4rem 3.2rem"
          mt="8rem"
          rightIcon={<ChevronRightIcon boxSize="2.4rem" />}
          data-heapid="save-continue-button"
        >
          Save and continue
        </Button>
      </form>
    </>
  );
};
