import { useEffect, useState } from "react";
import environment from "../../../environments/environment";
import {
  getSessionID,
  randomUUID,
  sessionExpired,
  statusCollection,
  trackApiException,
} from "../Service.utils";
import {
  ErrorResponse,
  isErrorResponse,
  RequestOptions,
  ServiceType,
  Status,
} from "../Service.types";

export const BcaProductsAPI = <Response, ErrorFields = string>(): ServiceType<
  Response,
  ErrorFields
> => {
  const headers: Record<string, string> = {
    "Client-Channel": "DEP_ONBOARDING",
    "Client-Version": process.env.REACT_APP_VERSION ?? "1.0.0",
    "Content-Type": "application/json",
    "X-Session-Id": getSessionID(),
  };
  const token = sessionStorage.getItem("token");
  if (token) headers.Authorization = `Bearer ${token}`;

  const [response, setResponse] = useState<Response>({} as Response);
  const [status, setStatus] = useState<Status>(statusCollection.pending);
  const [error, setError] = useState<ErrorResponse<ErrorFields>>({});

  useEffect(() => {
    setResponse({} as Response);
    setError({});
    setStatus(statusCollection.pending);
  }, []);

  const request = async (options: RequestOptions = {}) => {
    setStatus(statusCollection.loading);
    try {
      headers["X-Correlation-Id"] = randomUUID();
      const res = await fetch(
        `${environment.api.bcaProducts}/summaries?productSubType=REWARDS_ACCOUNT`,
        {
          headers,
          ...options,
        },
      );
      if (res.status === 401) sessionExpired();
      if (res.ok) {
        const data = await res.text();
        if (data) setResponse(JSON.parse(data));
        setStatus(statusCollection.success);
      } else {
        const error = await res.text();
        const errorResponse = { ...(error && JSON.parse(error)), status: res.status };
        throw errorResponse;
      }
    } catch (errorResponse: unknown) {
      if (isErrorResponse<ErrorFields>(errorResponse)) setError(errorResponse);
      else setError({ status: 99, text: "Unable to load results" });
      setStatus(statusCollection.error);
      trackApiException({
        endpoint: `${environment.api.bcaProducts}`,
        method: options.method,
        headers,
        errorResponse,
      });
    }
  };

  return { response, status, error, request, setResponse, setStatus };
};
