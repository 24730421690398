import { ErrorFillIcon } from "@allica/ui-react";
import { CircleOutlineIcon, ConfirmFillIcon } from "src/components/icon";
import { StepIconProps, StepStatus } from "../types";

export const StepIcon = ({ status }: StepIconProps) => {
  const stepIcons = {
    [StepStatus.COMPLETE]: { icon: ConfirmFillIcon, props: { color: "$fg.system.success" } },
    [StepStatus.INACTIVE]: {
      icon: ConfirmFillIcon,
      props: { color: "neutral.400", fill: "neutral.400" },
    },
    [StepStatus.INCOMPLETE]: {
      icon: CircleOutlineIcon,
      props: { color: "$fg.system.success", fill: "$fg.inverse.primary" },
    },
    [StepStatus.ALERT]: { icon: ErrorFillIcon, props: { color: "mango.400" } },
  };

  const StepIcon = stepIcons[status]?.icon;

  if (!StepIcon) return null;

  return (
    <StepIcon
      data-testid={status}
      display="flex"
      zIndex={1}
      h="2.4rem"
      w="2.4rem"
      mr="1.2rem"
      {...stepIcons[status].props}
    />
  );
};
