import { ErrorFillIcon } from "@allica/ui-react";
import { ConfirmFillIcon } from "src/components/icon";
import { TaskChecklistItemStatus } from "../TaskChecklist.types";

export const TaskChecklistStatusIcon = ({ status }: { status: TaskChecklistItemStatus }) => {
  switch (status) {
    case "complete":
      return (
        <ConfirmFillIcon
          data-testid="confirmComplete"
          color="$fg.system.success"
          fill="neutral.0"
          mr="1.6rem"
        />
      );
    case "error":
      return (
        <ErrorFillIcon
          data-testid="errorFill"
          color="$fg.system.error_default"
          fill="neutral.0"
          mr="1.6rem"
        />
      );
    default:
      return (
        <ConfirmFillIcon
          data-testid="confirmIncomplete"
          color="neutral.300"
          fill="neutral.300"
          mr="1.6rem"
        />
      );
  }
};
