import { ErrorIcon } from "@allica/ui-react";
import { Input, InputGroup, InputLeftAddon, InputProps, InputRightElement } from "@chakra-ui/react";
import { ChangeEvent, forwardRef, useEffect, useState } from "react";
import { formatPercentage } from "./PercentageInput.utils";

export const PercentageInput = forwardRef<HTMLInputElement, InputProps>(
  ({ value, onChange, isInvalid, w, ...rest }, ref) => {
    const [inputValue, setValue] = useState(formatPercentage(value as string));

    useEffect(() => {
      if (inputValue !== value) {
        setValue(formatPercentage(value as string));
      }
    }, [value]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      if (value === ".") return;

      const percentageRegex = /^(100|\d{1,2})?$/;
      const decimalRegex = /^\d{1,2}\.\d{0,2}$/;
      const hasDecimal = decimalRegex.test(value);
      const isValid = percentageRegex.test(value) || value === "";

      if (isValid || hasDecimal) {
        setValue(hasDecimal ? value : formatPercentage(value));
        onChange && onChange(e);
      }
    };
    return (
      <InputGroup w={w ?? "100%"}>
        <Input
          bg="neutral.0"
          type="text"
          inputMode="decimal"
          ref={ref}
          value={inputValue}
          onChange={handleChange}
          {...rest}
        />

        <InputRightElement>
          <InputLeftAddon
            bg="none"
            border="none"
            children="%"
            fontSize="2.25rem"
            color="darkdenim.500"
          />
          {isInvalid && (
            <ErrorIcon
              data-testid="percentageInputErrorIcon"
              color="$fg.system.error_default"
              mr="3.2rem"
            />
          )}
        </InputRightElement>
      </InputGroup>
    );
  },
);
