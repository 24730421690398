import { useEffect } from "react";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { Button, ChevronRightIcon, Heading, PageLayout, Text } from "@allica/ui-react";
import { Box, Card, Flex, GridItem } from "@chakra-ui/react";

import { appInsights } from "src/core/app/ApplicationInsights";
import { DepositsAPI } from "src/core/service";
import environment from "src/environments/environment";
import { FooterInformation } from "src/shared/outcome/footerInformation/FooterInformation";

import styles from "../../../../../shared/outcome/Outcome.module.css";

interface RefreshTokenResponse {
  accessToken: string;
  expiresIn: number;
  refreshToken: string;
}

/**
 * @deprecated - To remove once BE is implemented and FF is wiped
 */
const DepositSuccessOld = () => {
  const { status, request, response } = DepositsAPI<RefreshTokenResponse>(
    "applications/refresh-token",
  );

  const handleClick = () => {
    const refreshToken = sessionStorage.getItem("refreshToken");
    request({
      method: "POST",
      body: JSON.stringify({ refreshToken }),
    });
  };

  useEffect(() => {
    if (status.success) {
      appInsights.trackEvent({ name: `online-banking-login-success` });
      sessionStorage.setItem("token", response.accessToken);
      sessionStorage.setItem("refreshToken", response.refreshToken);
      window.location.href = environment.ui.onlineBanking + "/accounts";
    }

    if (status.error) {
      appInsights.trackEvent({ name: `error-on-ping-refresh-token` });
      appInsights.trackException({
        exception: new Error("error-on-ping-refresh-token"),
        severityLevel: SeverityLevel.Error,
      });
      // Force login screen on error
      sessionStorage.clear();
      window.location.href = environment.ui.onlineBanking;
    }
  }, [status]);

  return (
    <>
      <Box className={styles.outcomeHeader}>
        <PageLayout
          paddingBottom="3.6rem"
          paddingTop={{ base: "23.5rem", sm: "40rem", md: "8.8rem", xl: "4.8rem" }}
        >
          <GridItem
            colStart={{ base: 1, sm: 1, md: 1, lg: 1 }}
            colSpan={{ base: 4, sm: 8, md: 5, lg: 6 }}
            padding={0}
          >
            <Heading
              color="neutral.0"
              fontSize={{ base: "4rem", md: "4.8rem" }}
              lineHeight={{ base: "4.8rem", md: "5.6rem" }}
              textAlign={{ base: "center", md: "left" }}
            >
              Congratulations! <br />
              Your account is now open
            </Heading>
          </GridItem>
        </PageLayout>
      </Box>
      <Box className={styles.outcomeImage}>
        <PageLayout paddingBottom="3.2rem" paddingTop={{ base: "2rem", md: "3.6rem" }}>
          <GridItem
            as="section"
            colStart={{ base: 1, sm: 1, md: 1, lg: 1 }}
            colSpan={{ base: 4, sm: 8, md: 5, lg: 6 }}
          >
            <Heading
              color="darkdenim.500"
              fontSize={{ base: "2rem", md: "2.4rem" }}
              lineHeight={{ base: "2.8rem", md: "3.2rem" }}
            >
              What happens next?
            </Heading>
            <Card p="2.4rem" mt="2.4rem" boxShadow="none" borderRadius="0.4rem">
              <Text color="denim.900" textStyle="body-02-regular">
                You now have 14 days to deposit money into your account and start saving. To make
                the first deposit follow these two easy steps:
              </Text>
              <Heading
                color="darkdenim.500"
                fontSize={{ base: "1.6rem", md: "2rem" }}
                lineHeight={{ base: "2rem", md: "2.4rem" }}
                mt="3.2rem"
              >
                Step 1 - Access your account
              </Heading>
              <Box mt="1.6rem" p="2.6rem" backgroundColor="$bg.base">
                <Text textStyle="body-01-regular" color="darkdenim.500">
                  Click the 'Go to my account' button below to see your new details including the
                  sort code and account number.
                </Text>
              </Box>
              <Heading
                color="darkdenim.500"
                fontSize={{ base: "1.6rem", md: "2rem" }}
                lineHeight={{ base: "2rem", md: "2.4rem" }}
                mt="3.2rem"
              >
                Step 2 - Fund your account{" "}
              </Heading>
              <Box mt="1.6rem" p="2.6rem" backgroundColor="$bg.base">
                <Text textStyle="body-01-regular" color="darkdenim.500">
                  Make a payment to your new Allica account from the bank account you nominated
                  during your application within the 14 day funding window.
                </Text>
              </Box>
            </Card>

            <Flex justifyContent="end" mt="6.4rem">
              <Button
                onClick={handleClick}
                isLoading={status.loading}
                loadingText="Go to my account"
                spinnerPlacement="end"
                padding="2.4rem 3.2rem"
                rightIcon={<ChevronRightIcon boxSize="2.4rem" />}
              >
                Go to my account
              </Button>
            </Flex>

            <Box mt="6.4rem" mb="12rem">
              <FooterInformation />
            </Box>
          </GridItem>
        </PageLayout>
      </Box>
    </>
  );
};

export default DepositSuccessOld;
