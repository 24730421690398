import { StepStatus } from "@allica/ui-react";
import { isFeatureActive } from "src/components/feature-toggle/FeatureToggle";
import { FeatureFlag } from "src/environments/feature.flags";
import { ApplicationSections, Stage } from "src/pages/business-rewards/BusinessRewards.types";
import ApplicantUI from "src/shared/applicant/ApplicantUI";
import { useBusinessRewardsContext } from "../../context/BusinessRewardsContext";

const isPhaseTwo = isFeatureActive(FeatureFlag.BRA_PHASE_TWO);

const BusinessRewardsApplicant = () => {
  const {
    businessRewardsData,
    setCurrentStage,
    updateStepperConfig,
    setShowGenericError,
    setBusinessRewardsData,
    alertStages,
    setAlertStages,
    stepOrder,
  } = useBusinessRewardsContext();

  const updateDataAndStepper = (updatedApplicationSectionData: ApplicationSections) => {
    const stage = isPhaseTwo ? Stage.DEPOSIT : Stage.SUMMARY;
    const nextStep = stepOrder.includes(Stage.PARTIES) ? Stage.PARTIES : stage;
    const newAlertStages = [...alertStages];

    const applicantNodeId = updatedApplicationSectionData.applicantSection?.nodeId;
    const otherPartiesNodeId =
      businessRewardsData.businessRewardsApplicationSections.otherPartiesSection?.nodeId;
    const isSameParty = otherPartiesNodeId !== undefined && applicantNodeId === otherPartiesNodeId;
    const { otherPartiesSection } = businessRewardsData.businessRewardsApplicationSections;
    const updatedOtherPartiesSection =
      !!otherPartiesSection && isSameParty ? null : otherPartiesSection;

    setBusinessRewardsData({
      ...businessRewardsData,
      businessRewardsApplicationSections: {
        ...updatedApplicationSectionData,
        otherPartiesSection: updatedOtherPartiesSection,
      },
    });

    let nextStepStatus =
      nextStep === Stage.PARTIES && alertStages.includes(Stage.PARTIES)
        ? StepStatus.ALERT
        : StepStatus.INCOMPLETE;

    if (isSameParty) {
      nextStepStatus = StepStatus.ALERT;
      if (!newAlertStages.includes(Stage.PARTIES)) newAlertStages.push(Stage.PARTIES);
    }

    setCurrentStage(nextStep);
    updateStepperConfig(
      [
        { stage: Stage.APPLICANT, value: { status: StepStatus.COMPLETE } },
        { stage: nextStep, value: { status: nextStepStatus, disabled: false } },
      ],
      isSameParty, // only override the complete status if the main applicant and other party are the same individual
    );

    if (newAlertStages.length) {
      const alertStages = newAlertStages.filter((alertStage) => alertStage !== Stage.APPLICANT);
      setAlertStages(alertStages);
    }
  };

  return (
    <ApplicantUI
      businessApplicationSections={businessRewardsData.businessRewardsApplicationSections}
      applicationID={businessRewardsData.applicationID}
      setShowGenericError={setShowGenericError}
      alertStages={alertStages}
      setAlertStages={setAlertStages}
      updateDataAndStepper={updateDataAndStepper}
    />
  );
};

export default BusinessRewardsApplicant;
