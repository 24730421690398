import { ListItem } from "@chakra-ui/react";
import { Step } from "../../step/step";
import { StepConnector } from "../step-connector/step-connector";
import { StepListItemProps } from "../../types";
import { getStepConnectorColor } from "../../helpers";

export const StepListItem = ({ followsAlertStep, ...stepProps }: StepListItemProps) => {
  const { disableConnector, status } = stepProps;

  return (
    <ListItem
      listStyleType="none"
      position="relative"
      top={disableConnector ? "0" : "-0.3rem"}
      h={disableConnector ? "2.4rem" : "4.4rem"}
    >
      {!disableConnector && (
        <StepConnector color={getStepConnectorColor(status, followsAlertStep)} />
      )}
      <Step {...stepProps} />
    </ListItem>
  );
};
