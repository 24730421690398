import { useEffect, useState } from "react";
import {
  Alert,
  AlertAction,
  AlertActions,
  AlertDescription,
  AlertIcon,
  Button,
  ChevronRightIcon,
  ErrorIcon,
  FormControl,
  Heading,
  StepStatus,
  Text,
} from "@allica/ui-react";
import {
  Box,
  Card,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  Link,
  Select,
  Stack,
} from "@chakra-ui/react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useStore } from "src/core/store/StoreContext";

import { DepositsAPI } from "src/core/service";
import { useBusinessRewardsContext } from "../../context/BusinessRewardsContext";
import { Stage } from "../../BusinessRewards.types";
import { ApplicationStatusResponse } from "src/core/service/deposits-api/DepositsApi.types";
import { dateValidate, emptyErrorDataHeapId, moveUKToFirstPosition } from "src/core/utils";
import { getDefaultDescription } from "src/shared/applicant/Applicant.utils";
import { formatDateToDisplay } from "src/shared/company/Company.utils";
import { validatePhoneNumber } from "src/components/input/international-phone/utils/InternationalPhone.utils";
import { defaultAddressDetails } from "src/components/address/manage-address/manageAddress.utils";

import TaxResidency from "src/components/tax-residency/TaxResidency";
import { PercentageInput } from "src/components/input/percentage/PercentageInput";
import { FormGroup } from "src/components/input-set/FormGroup";
import MaskedInput from "src/components/masked-input/MaskedInput";
import { BaseInput } from "src/components/input/default/Input";
import Nationality from "src/components/Nationality/Nationality";
import AddressWrapper from "src/components/address/address-wrapper/AddressWrapper";
import InternationalPhoneInput from "src/components/input/international-phone/InternationalPhoneInput";
import Checkbox from "src/components/checkbox/Checkbox";

import {
  OtherPartiesFormErrorFields,
  OtherPartiesFormValues,
} from "./BusinessRewardsOtherParties.types";
import {
  convertOtherPartiesDataIntoBEFormat,
  dobOtherPartiesDataHeapId,
  emailOtherPartiesDataHeapId,
  getParsedErrorList,
  phoneNumberOtherPartiesDataHeapId,
} from "./utils/BusinessRewardsOtherParties.utils";

// Same regex pattern used by the BE team
const EMAIL_PATTERN =
  /^(?=^.{1,100}$)([^.])[a-zA-Z0-9]*([\w.'!#$%&*+\\\-/=?^`{|}~])*([a-zA-Z0-9])*@([a-zA-Z0-9]+([.\\-]))+[a-zA-Z0-9]*/g;

export const BusinessRewardsOtherParties = () => {
  const {
    setCurrentStage,
    updateStepperConfig,
    businessRewardsData,
    setBusinessRewardsData,
    setAlertStages,
    alertStages,
    setShowGenericError,
  } = useBusinessRewardsContext();
  const referenceData = useStore();
  const [showManualAdd, setShowManualAdd] = useState(
    !!businessRewardsData.businessRewardsApplicationSections.otherPartiesSection,
  );
  const countries = moveUKToFirstPosition(referenceData?.countries);

  const {
    status: otherPartiesStatus,
    request: saveOtherPartiesData,
    error: otherPartiesError,
  } = DepositsAPI<ApplicationStatusResponse, OtherPartiesFormErrorFields>(
    `applications/businesses/${businessRewardsData.applicationID}/shareholders`,
  );

  const mainApplicantNodeId =
    businessRewardsData.businessRewardsApplicationSections.applicantSection!.nodeId;
  const connectedIndividuals =
    businessRewardsData.businessRewardsApplicationSections.companySection.companiesHouseResponse
      .businessProfile.connectedIndividuals;

  // considers only one "other party"
  const [otherParty] = connectedIndividuals!.filter(
    (individual) => individual.nodeId !== mainApplicantNodeId,
  );
  const { nationalities, taxIdentifications, dateOfBirthSCV, addresses, ...rest } = otherParty;

  const methods = useForm<OtherPartiesFormValues>({
    defaultValues: businessRewardsData.businessRewardsApplicationSections.otherPartiesSection || {
      ...rest,
      role: rest?.businessIndividualRelations
        ?.map((relation) =>
          getDefaultDescription(relation?.type, referenceData.businessIndividualRelationTypes),
        )
        .join(" / "),
      dateOfBirthSCV: dateOfBirthSCV ? formatDateToDisplay(dateOfBirthSCV) : "",
      nationalities: nationalities?.map((item) => ({ value: item })) as { value: string }[],
      taxResidencies: taxIdentifications?.filter((item) => item?.taxCountryCode !== "GB"),
      otherPartiesHomeAddress:
        Array.isArray(addresses) && addresses.length ? addresses[0] : defaultAddressDetails,
      phone: "",
      emailAddress: "",
      shareHoldingPercentage: "",
    },
  });

  const {
    register,
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    setError,
  } = methods;

  const { onChange: dateOfBirthRefOnchange, ...restReg } = register("dateOfBirthSCV", {
    required: "Please enter a date of birth",
    validate: dateValidate,
  });

  const onSuccess = () => {
    setBusinessRewardsData({
      ...businessRewardsData,
      businessRewardsApplicationSections: {
        ...businessRewardsData.businessRewardsApplicationSections,
        otherPartiesSection: { ...getValues(), confirmation: "" },
      },
    });

    const updatedSteps = [
      { stage: Stage.PARTIES, value: { status: StepStatus.COMPLETE } },
      { stage: Stage.DEPOSIT, value: { status: StepStatus.INCOMPLETE } },
    ];

    setCurrentStage(Stage.DEPOSIT);
    setAlertStages([...alertStages.filter((stage) => stage !== "parties")]);
    updateStepperConfig(updatedSteps);
  };

  const onSubmit: SubmitHandler<OtherPartiesFormValues> = (data: OtherPartiesFormValues) => {
    const payload = convertOtherPartiesDataIntoBEFormat(data);

    saveOtherPartiesData({
      method: "PATCH",
      body: JSON.stringify(payload),
    });
  };

  useEffect(() => {
    otherPartiesStatus.success && onSuccess();
  }, [otherPartiesStatus.success]);

  useEffect(() => {
    if (!otherPartiesStatus.error) {
      return;
    }

    if (otherPartiesError.code !== "VALIDATION_ERROR") {
      setShowGenericError(true);
      return;
    }
    const errorsList = getParsedErrorList(otherPartiesError);

    for (const errors in errorsList) {
      setError(
        `${errors}` as keyof OtherPartiesFormValues,
        {
          message: errorsList[errors],
        },
        { shouldFocus: true },
      );
    }
  }, [otherPartiesStatus.error]);

  return (
    <>
      <Heading size="h1" as="h1" mb="2.4rem">
        Other parties
      </Heading>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Stack gap="6.4rem" mt="6.4rem">
            <Stack gap="3.2rem">
              <Heading size="h2" as="h2">
                Personal Details
              </Heading>
              <FormGroup label="First name" isRequired>
                <BaseInput {...register("firstName")} isInvalid={false} isDisabled />
              </FormGroup>
              <FormGroup label="Last name" isRequired>
                <BaseInput isInvalid={false} {...register("lastName")} isDisabled />
              </FormGroup>
              <FormGroup label="Role" isRequired>
                <BaseInput isInvalid={false} {...register("role")} isDisabled />
              </FormGroup>
              <FormGroup
                label="Date of birth"
                isRequired
                info={<Text>DD/MM/YYYY</Text>}
                error={errors.dateOfBirthSCV?.message}
                data-heapid={dobOtherPartiesDataHeapId(errors?.dateOfBirthSCV?.message)}
              >
                <InputGroup width="24rem">
                  <MaskedInput
                    maskedPattern="**/**/****"
                    maskedChar="*"
                    validate={(value: string) => /^\d+$/?.test(value)}
                    data-heapid="dob-2other-enter"
                    onChangeHandler={(e) => {
                      dateOfBirthRefOnchange(e);
                    }}
                    {...restReg}
                  />
                  <InputRightElement>
                    {!!errors?.dateOfBirthSCV && <ErrorIcon color="$fg.system.error_default" />}
                  </InputRightElement>
                </InputGroup>
              </FormGroup>
              <FormGroup
                label="Country of birth"
                isRequired
                error={errors.countryOfBirth?.message}
                data-heapid={emptyErrorDataHeapId(
                  errors?.countryOfBirth?.type,
                  "2othercountrybirth",
                )}
              >
                <Select
                  {...register("countryOfBirth", {
                    required: "Please select a country of birth",
                  })}
                  placeholder="Please select"
                  data-heapid="2othercountrybirth-list-select"
                >
                  {countries?.map((item) => (
                    <option key={item?.name} value={item?.name}>
                      {item?.description}
                    </option>
                  ))}
                </Select>
              </FormGroup>
              <Nationality heapName="2othernationality" />
              <FormGroup isRequired label="Home Address">
                <Card
                  variant="outline"
                  bgColor="$bg.primary"
                  px="3.2rem"
                  mt="1.6rem"
                  paddingTop="2.4rem"
                  paddingBottom="4rem"
                >
                  <AddressWrapper
                    addressTypeLabel={""}
                    addressHelperText={"Start typing your address or postcode"}
                    showManualAdd={showManualAdd}
                    setShowManualAdd={setShowManualAdd}
                    fieldRootPath={"otherPartiesHomeAddress"}
                    heapId={{
                      onAddressSearchEnter: "2otherhome-address-lookup",
                      onManualButtonClick: "2otherhome-address-manual",
                      onEmptyError: "2otherhome-empty-error",
                    }}
                  />
                </Card>
              </FormGroup>
            </Stack>
            <Stack gap="3.2rem">
              <Heading size="h2" as="h2">
                Financial details
              </Heading>
              <FormGroup
                label="Shareholding"
                error={errors.shareHoldingPercentage?.message}
                isRequired
                data-heapid={emptyErrorDataHeapId(
                  errors?.shareHoldingPercentage?.type as string,
                  "2othershareholding",
                )}
              >
                <Controller
                  control={control}
                  name="shareHoldingPercentage"
                  rules={{
                    required: "Please enter shareholding from 0-100",
                    min: {
                      value: 0,
                      message: "Please enter shareholding from 0-100",
                    },
                    max: {
                      value: 100,
                      message: "Please enter shareholding from 0-100",
                    },
                  }}
                  render={({ field }) => (
                    <PercentageInput
                      w="15.2rem"
                      isInvalid={!!errors?.shareHoldingPercentage}
                      data-heapid="2othershareholding-number-entry"
                      {...field}
                    />
                  )}
                />
              </FormGroup>
              <TaxResidency
                heapName="2othertax"
                hideHeading
                label="Is the individual a taxpayer outside the UK?"
              />
            </Stack>
            <Stack gap="3.2rem">
              <Heading size="h2" as="h2">
                Contact details
              </Heading>
              <FormGroup
                label="Email"
                isRequired
                error={errors?.emailAddress?.message}
                data-heapid={emailOtherPartiesDataHeapId(errors?.emailAddress?.type)}
              >
                <BaseInput
                  {...register("emailAddress", {
                    required: "Please enter a valid email address",
                    pattern: {
                      value: EMAIL_PATTERN,
                      message: "Please enter a valid email address",
                    },
                  })}
                  type="email"
                  isInvalid={!!errors?.emailAddress}
                  data-heapid="email-2other-enter"
                />
              </FormGroup>
              <FormGroup
                label="Phone"
                error={errors?.phone?.message}
                isRequired
                data-heapid={phoneNumberOtherPartiesDataHeapId(errors?.phone?.type)}
              >
                <Controller
                  control={control}
                  rules={{
                    required: "Please enter a valid phone number",
                    validate: validatePhoneNumber,
                  }}
                  name="phone"
                  render={({ field }) => (
                    <InternationalPhoneInput
                      heapPrefix="2other"
                      isInvalid={!!errors?.phone}
                      {...field}
                    />
                  )}
                />
              </FormGroup>
              <Alert status="info">
                <AlertIcon />
                <Box>
                  <AlertDescription mb="1.6rem">
                    All information held at Companies House must be accurate before applying. If
                    it’s not accurate, please contact Companies House and update on their system.
                  </AlertDescription>
                  <AlertActions>
                    <AlertAction>
                      <Link
                        color="$fg.interactive.default"
                        textStyle="body-02-medium"
                        fontWeight="600"
                        textDecoration="none"
                        variant="plain"
                        target="_blank"
                        href="https://www.gov.uk/government/organisations/companies-house"
                      >
                        Go to Companies House
                      </Link>
                    </AlertAction>
                  </AlertActions>
                </Box>
              </Alert>
              <FormControl isInvalid={!!errors.confirmation}>
                {/* https://github.com/react-hook-form/react-hook-form/discussions/6838#discussioncomment-2093357 */}
                <Controller
                  control={control}
                  name="confirmation"
                  render={({ field: { ref, ...rest } }) => (
                    <Checkbox
                      data-heapid="2otherconfirmation-accurate-checkbox"
                      ref={ref}
                      {...rest}
                    >
                      I confirm all information at Companies House shown on this page is accurate
                    </Checkbox>
                  )}
                  rules={{
                    required: "Please confirm all information is accurate",
                  }}
                />
                <FormErrorMessage
                  data-heapid="2otherconfirmation-empty-error"
                  mt="1.2rem"
                  mb="2.4rm"
                >
                  <>{errors?.confirmation && errors?.confirmation.message}</>
                </FormErrorMessage>
              </FormControl>
            </Stack>
          </Stack>
          <Button
            isLoading={otherPartiesStatus.loading}
            loadingText="Save and continue"
            spinnerPlacement="end"
            type="submit"
            float="right"
            mt="8rem"
            padding="2.4rem 3.2rem"
            rightIcon={<ChevronRightIcon boxSize="2.4rem" />}
            data-heapid="2othersave-continue-button"
          >
            Save and continue
          </Button>
        </form>
      </FormProvider>
    </>
  );
};
