import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  Heading,
  StepStatus,
  Text,
} from "@allica/ui-react";
import { VStack } from "@chakra-ui/react";
import { SummaryListContainer } from "src/components/summary-list-container/SummaryListContainer";
import { dateFormatReverse, formatCurrency, getAndJoinDescriptions } from "src/core/utils";
import { useEffect, useState } from "react";
import { useBusinessRewardsContext } from "../../context/BusinessRewardsContext";
import { Stage } from "../../BusinessRewards.types";
import { useStore } from "src/core/store/StoreContext";
import { getAddress, getSicCodes } from "src/shared/application-summary/ApplicationSummary.utils";
import ErrorBanner from "src/components/connection-error/ErrorBanner";
import { Overlay } from "src/components/overlay/Overlay";
import { getOptionDescriptionsByIds } from "../account-activity/BusinessRewardsAccountActivity.utils";
import { DepositsAPI } from "src/core/service";
import {
  ApplicationStatusResponse,
  StageOptions,
} from "src/core/service/deposits-api/DepositsApi.types";
import { isFeatureActive } from "src/components/feature-toggle/FeatureToggle";
import { FeatureFlag } from "src/environments/feature.flags";
import { appInsights } from "src/core/app/ApplicationInsights";

const otherPartiesActive = isFeatureActive(FeatureFlag.OTHER_PARTIES);

export const BusinessRewardsApplicationSummary = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [checkedDetails, setCheckedDetails] = useState<boolean>(false);
  const [checkBoxError, setCheckboxError] = useState<boolean>(false);
  const [showErrorBanner, setShowErrorBanner] = useState<boolean>(false);
  const referenceData = useStore();

  const {
    setCurrentStage,
    updateStepperConfig,
    setBusinessRewardsData,
    businessRewardsData,
    alertStages,
    stepOrder,
    stepperConfig,
  } = useBusinessRewardsContext();

  const {
    applicationID,
    questions,
    businessRewardsApplicationSections: {
      signUpSection,
      companySection,
      applicantSection,
      otherPartiesSection,
      depositSection,
      accountSection,
    },
  } = businessRewardsData;

  const showOtherParties = otherPartiesActive && stepOrder.includes(Stage.PARTIES);
  const showOtherPartiesWarnings =
    showOtherParties &&
    stepperConfig.applicant.status === StepStatus.COMPLETE &&
    alertStages.includes(Stage.PARTIES);

  const {
    status: summaryStatus,
    request: submitSummary,
    response: summaryResponse,
    error: summaryError,
  } = DepositsAPI<ApplicationStatusResponse>(`applications/businesses/${applicationID}/summary`);

  const {
    status: statusInfoStatus,
    response: statusInfoResponse,
    request: statusInfoRequest,
  } = DepositsAPI<ApplicationStatusResponse>(`applications/${applicationID}/status-info`);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!checkedDetails || !!alertStages.length) {
      setCheckboxError(true);
    } else {
      setShowErrorBanner(false);
      submitSummary({
        method: "PATCH",
        body: JSON.stringify({ detailsVerified: true }),
      });
      setIsLoading(true);
    }
  };

  const showError = () => {
    setIsLoading(false);
    setShowErrorBanner(true);
    appInsights.trackEvent({ name: `submit-business-rewards-account-application-failed` });
  };

  const setRefer = () => {
    const newData = { ...businessRewardsData };
    newData.businessRewardsApplicationSections.summarySection = { detailsVerified: true };
    newData.businessRewardsApplicationSections.outcome = { success: false };
    newData.cobApplicationId =
      summaryResponse.cobApplicationId || statusInfoResponse.cobApplicationId;
    setBusinessRewardsData(newData);

    appInsights.trackEvent({ name: `business-rewards-account-outcome-refer` });
    appInsights.trackEvent({ name: `business-rewards-account-completed` });
    updateStepperConfig([{ stage: Stage.SUMMARY, value: { status: StepStatus.COMPLETE } }]);
  };

  useEffect(() => {
    // on success, no matter what response - the application is referred (mvp)
    if (summaryStatus.success) setRefer();
    if (summaryStatus.error) {
      // on gateway timeout check the application status
      if (summaryError.status === 504) {
        statusInfoRequest();
      } else {
        showError();
      }
    }
  }, [summaryStatus]);

  useEffect(() => {
    if (statusInfoStatus.success) {
      if (summaryResponse.cobApplicationId) {
        appInsights.trackEvent({
          name: `bra-cob-application-id`,
          properties: { cobID: summaryResponse.cobApplicationId },
        });
      }

      if (statusInfoResponse.applicationStage === StageOptions.SUMMARY) {
        // display an error and allow the user to continue making modifications to the application
        showError();
      } else {
        // refer for any other applicationStage
        setRefer();
      }
    }

    if (statusInfoStatus.error) showError();
  }, [statusInfoStatus]);

  return (
    <>
      {isLoading && <Overlay text="Submitting application, please wait..." spinner />}
      <VStack
        alignItems="start"
        gap="$space.group.gap.xl"
        mb={{
          base: "$space.page.header.margin_bottom.base",
          md: "$space.page.header.margin_bottom.md",
        }}
      >
        <VStack alignItems="start" gap="$space.group.gap.md">
          <Heading size="h1" as="h1">
            Application summary
          </Heading>
          <Text color="$fg.secondary" textStyle="body-02-regular">
            Before submitting your application, please make sure all the details you have supplied
            are correct.
          </Text>
        </VStack>
        {showErrorBanner && (
          <ErrorBanner
            mb={0}
            title="Something went wrong"
            description="Please retry saving your details by clicking the Submit button."
            data-heapid="wrong-retry-error"
          />
        )}
      </VStack>
      <SummaryListContainer
        title="Account"
        listItems={[{ title: "Account", description: "Business Rewards Account" }]}
      />
      <SummaryListContainer
        title="Sign up"
        listItems={[
          { title: "Email", description: signUpSection.emailAddress },
          { title: "Mobile number", description: signUpSection.phoneNumber },
        ]}
      />
      <SummaryListContainer
        onEdit={() => setCurrentStage(Stage.BUSINESS)}
        heapId="edit-business-details"
        title="Business details"
        listItems={[
          { title: "Company name", description: companySection.businessName },
          { title: "Company No.", description: companySection.companyHouseNumber },
          {
            title: "Incorporated on",
            description: dateFormatReverse(companySection.businessStartDate),
          },
          {
            title: "Tax Status",
            description: getAndJoinDescriptions(
              companySection.taxIdentifications.map((i) => i.taxCountryCode),
              referenceData.countries,
            ),
          },
          {
            title: "Registered office address",
            description: getAddress("REGISTERED_OFFICE_ADDRESS", companySection.addresses),
          },
          {
            title: "Trading address",
            description: getAddress("TRADING_ADDRESS", companySection.addresses),
          },
          {
            title: "Correspondence address",
            description: getAddress("CORRESPONDENCE_ADDRESS", companySection.addresses),
          },
          {
            title: "Nature of business (SIC)",
            description: getSicCodes(companySection.businessSicCodes, referenceData.sicCodes),
          },
          { title: "Business description", description: companySection.businessExplanation },
          {
            title: "Countries of operation",
            description: getAndJoinDescriptions(
              companySection.internationalTradingCountryIsoCodes,
              referenceData.countries,
            ),
          },
          {
            title: "Estimated annual turnover",
            description: formatCurrency(companySection.expectedAnnualTurnover),
          },
          { title: "Number of employees", description: companySection.numberOfEmployees },
        ]}
      />
      <SummaryListContainer
        onEdit={() => setCurrentStage(Stage.APPLICANT)}
        heapId="edit-main-applicant"
        title="Main applicant"
        listItems={[
          { title: "First name", description: applicantSection?.firstName ?? "" },
          { title: "Last name", description: applicantSection?.lastName ?? "" },
          {
            title: "Role",
            description: applicantSection?.businessIndividualRelations
              ? getAndJoinDescriptions(
                  applicantSection.businessIndividualRelations.map((i) => i.type),
                  referenceData.businessIndividualRelationTypes,
                )
              : "",
          },
          {
            title: "Date of birth",
            description: applicantSection?.dateOfBirthSCV
              ? dateFormatReverse(applicantSection.dateOfBirthSCV)
              : "",
          },
          {
            title: "Country of birth",
            description: applicantSection?.countryOfBirth
              ? getAndJoinDescriptions([applicantSection.countryOfBirth], referenceData.countries)
              : "",
          },
          {
            title: "Nationality",
            description: applicantSection?.nationalities
              ? getAndJoinDescriptions(
                  applicantSection.nationalities as string[],
                  referenceData.nationality,
                )
              : "",
          },
          {
            title: "Shareholding",
            description: applicantSection?.shareHoldingPercentage
              ? `${applicantSection.shareHoldingPercentage}%`
              : "",
          },
          {
            title: "Tax Status",
            description: applicantSection?.taxIdentifications
              ? getAndJoinDescriptions(
                  applicantSection.taxIdentifications.map((i) => i.taxCountryCode),
                  referenceData.countries,
                )
              : "",
          },
          {
            title: "Home address",
            description: applicantSection?.addresses
              ? getAddress("HOME_ADDRESS", applicantSection.addresses)
              : "",
          },
        ]}
      />
      {showOtherPartiesWarnings && (
        <ErrorBanner
          title="Other parties page requires new information."
          titleCallback={{
            label: "Go to Other parties page",
            method: () => setCurrentStage(Stage.PARTIES),
          }}
        />
      )}
      {showOtherParties && (
        <SummaryListContainer
          onEdit={() => setCurrentStage(Stage.PARTIES)}
          title="Other parties"
          listItems={[
            { title: "First name", description: otherPartiesSection?.firstName ?? "" },
            { title: "Last name", description: otherPartiesSection?.lastName ?? "" },
            {
              title: "Role",
              description: otherPartiesSection?.businessIndividualRelations
                ? getAndJoinDescriptions(
                    otherPartiesSection.businessIndividualRelations.map((i) => i.type),
                    referenceData.businessIndividualRelationTypes,
                  )
                : "",
            },
            {
              title: "Date of birth",
              description: otherPartiesSection?.dateOfBirthSCV
                ? dateFormatReverse(otherPartiesSection.dateOfBirthSCV)
                : "",
            },
            {
              title: "Country of birth",
              description: otherPartiesSection?.countryOfBirth
                ? getAndJoinDescriptions(
                    [otherPartiesSection.countryOfBirth],
                    referenceData.countries,
                  )
                : "",
            },
            {
              title: "Nationality",
              description: otherPartiesSection?.nationalities
                ? getAndJoinDescriptions(
                    otherPartiesSection.nationalities.map((nationality) => nationality.value),
                    referenceData.nationality,
                  )
                : "",
            },
            {
              title: "Shareholding",
              description: otherPartiesSection?.shareHoldingPercentage
                ? `${otherPartiesSection.shareHoldingPercentage}%`
                : "",
            },
            {
              title: "Tax Status",
              description: otherPartiesSection?.taxResidencies
                ? getAndJoinDescriptions(
                    ["GB", ...otherPartiesSection.taxResidencies.map((i) => i.taxCountryCode)],
                    referenceData.countries,
                  )
                : "",
            },
            {
              title: "Home address",
              description: otherPartiesSection?.otherPartiesHomeAddress
                ? getAddress("HOME_ADDRESS", otherPartiesSection.otherPartiesHomeAddress)
                : "",
            },
            {
              title: "Email",
              description: otherPartiesSection?.emailAddress ?? "",
            },
            {
              title: "Phone number",
              description: otherPartiesSection?.phone ?? "",
            },
          ]}
        />
      )}
      <SummaryListContainer
        onEdit={() => setCurrentStage(Stage.DEPOSIT)}
        heapId="edit-deposit-amount"
        title="Deposit amount"
        listItems={[
          {
            title: "Deposit amount",
            description: formatCurrency(depositSection!.depositAmount),
          },
        ]}
      />
      <SummaryListContainer
        onEdit={() => setCurrentStage(Stage.ACCOUNT)}
        title="Account activity"
        listItems={[
          {
            title: "Purpose of account",
            description: getOptionDescriptionsByIds({
              optionIds: accountSection!.purposeOfAccount,
              question: questions!.PURPOSE_OF_ACCOUNT,
            }),
          },
          {
            title: "Monthly payments in",
            description: getOptionDescriptionsByIds({
              optionIds: [accountSection!.monthlyPaymentsIn],
              question: questions!.MONTHLY_PAYMENTS_IN,
            }),
          },
          {
            title: "Source of funds",
            description: getOptionDescriptionsByIds({
              optionIds: accountSection!.sourceOfFunds,
              question: questions!.SOURCE_OF_FUNDS,
            }),
          },
        ]}
      />
      <form onSubmit={handleSubmit}>
        <FormControl isInvalid={checkBoxError}>
          <Checkbox
            variant="individual"
            data-heapid="check-details-button"
            checked={checkedDetails}
            onChange={() => {
              setCheckboxError(false);
              setCheckedDetails(!checkedDetails);
            }}
          >
            I have checked my details and can confirm they are correct.
          </Checkbox>
          <FormErrorMessage data-heapid="details-button-error">
            Please check and confirm your details
          </FormErrorMessage>
        </FormControl>
        <Button
          mt="8rem"
          isLoading={isLoading}
          loadingText="Submit"
          spinnerPlacement="end"
          type="submit"
          float="right"
          padding="2.4rem 3.2rem"
          data-heapid="submit-application"
        >
          Submit
        </Button>
      </form>
    </>
  );
};
