import { useState } from "react";
import {
  Alert,
  AlertAction,
  AlertActions,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  ChevronRightIcon,
  Heading,
  Text,
} from "@allica/ui-react";
import { Box, Link, Stack } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { BaseInput } from "../../components/input/default/Input";
import { FormGroup } from "../../components/input-set/FormGroup";
import ReSendSecurityCode from "../../components/re-send-security-code/ReSendSecurityCode";
import { VerifyFormValues, VerifyUIProps } from "src/shared/verify/Verify.types";
import { PersonalSavingDataTypes } from "src/pages/personal-savings/PersonalSavings.types";
import { BusinessSavingDataTypes } from "src/pages/business-savings/BusinessSavings.types";
import { BusinessRewardsDataTypes } from "src/pages/business-rewards/BusinessRewards.types";

const VerifyUI = <
  DataType extends PersonalSavingDataTypes | BusinessSavingDataTypes | BusinessRewardsDataTypes,
>({
  onSubmit,
  isSubmitting,
  savingData,
  setSavingData,
  setShowGenericError,
  navigateToSignUp,
  noOfSecurityCodeSent,
  setNoOfSecurityCodeSent,
  phoneNumber,
}: VerifyUIProps<DataType>) => {
  const [OTP, setOTP] = useState<string>("");

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useFormContext<VerifyFormValues>();

  const otpFieldDataHeapId = (type: string | undefined) => {
    if (type === "required") {
      return "OTP-empty-error";
    }

    if (type) {
      return "OTP-valid-error";
    }

    return undefined;
  };

  return (
    <>
      <Heading size="h1" as="h1" mb="1.6rem">
        Verify your number
      </Heading>
      <Text textStyle="body-02-regular" mb="6.4rem" color="	neutral.600">
        We've sent an SMS with a security code to {phoneNumber.trim()}. You may have to wait a
        minute for it to arrive.
      </Text>
      {noOfSecurityCodeSent > 1 && (
        <Alert status="success" mt="6.4rem">
          <AlertIcon />
          <Box>
            <AlertTitle>Security code {noOfSecurityCodeSent} sent</AlertTitle>
            <AlertDescription>
              We've sent a security code to {phoneNumber.trim()}. You may have to wait a minute for
              it to arrive.
            </AlertDescription>
            <AlertActions>
              <AlertAction asChild>
                <Link
                  onClick={navigateToSignUp}
                  color="$fg.interactive.default"
                  data-heapid="update-phone-button"
                >
                  Update phone number
                </Link>
              </AlertAction>
            </AlertActions>
          </Box>
        </Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack spacing="3.2rem" mt="6.4rem">
          <FormGroup
            label="Enter security code"
            info="This is the 6 digit code we’ve sent to you"
            error={errors?.otp?.message}
            isRequired
            data-heapid={otpFieldDataHeapId(!!errors.otp ? (errors.otp.type as string) : undefined)}
          >
            <BaseInput
              {...register("otp", {
                required: "Please enter the security code sent to your mobile telephone number",
                maxLength: {
                  value: 6,
                  message: "Please enter a valid security code",
                },
                minLength: {
                  value: 6,
                  message: "Please enter a valid security code",
                },
                setValueAs: (value: string) => {
                  const regex = /^(\d{0,6})$/;
                  if (regex.test(value)) {
                    setOTP(value);
                    return value;
                  }
                  return OTP;
                },
              })}
              value={OTP}
              w="23.8rem"
              type="text"
              inputMode="numeric"
              isInvalid={!!errors?.otp}
              data-heapid="security-OTP-enter"
            />
          </FormGroup>

          <ReSendSecurityCode<DataType>
            setNoOfSecurityCodeSent={setNoOfSecurityCodeSent}
            noOfSecurityCodeSent={noOfSecurityCodeSent}
            applicationData={savingData}
            setApplicationData={setSavingData}
            setShowGenericError={setShowGenericError}
          />
        </Stack>

        <Button
          isLoading={isSubmitting}
          loadingText="Save and continue"
          spinnerPlacement="end"
          type="submit"
          float="right"
          mt="8rem"
          padding="2.4rem 3.2rem"
          rightIcon={<ChevronRightIcon boxSize="2.4rem" />}
          data-heapid="save-continue-button"
        >
          Save and continue
        </Button>
      </form>
    </>
  );
};

export default VerifyUI;
