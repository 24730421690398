import { ErrorIcon } from "@allica/ui-react";
import { Spinner } from "@chakra-ui/react";
import { ChangeEvent, useEffect, useLayoutEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { SearchIcon } from "src/components/icon";
import { FormGroup } from "src/components/input-set/FormGroup";
import { AddressDetailsApiResponse, ValidationAPI } from "src/core/service";
import { useStore } from "src/core/store/StoreContext";
import { AddressSearchProps, AddressSuggestions } from "./AddressSearch.types";
import { AutoCompleteSearch } from "../../../autocomplete";
import { getObjectValues } from "../../manage-address/manageAddress.utils";
import { emptyErrorDataHeapId } from "src/core/utils";

export const defaultAddressDetails = {
  addressLine1: "",
  addressLine2: "",
  addressLine3: "",
  city: "",
  county: "",
  postCode: "",
  countryIsoCode: "GB",
};

const setMultipleValues = (arr: { name: string; value: any }[], setterFn: any) => {
  arr.forEach(({ name, value }) => setterFn(name, value));
};

const AddressSearch = ({
  label,
  helperText = "",
  setShowManualAdd,
  fieldRootPath = "",
  heapId,
}: AddressSearchProps) => {
  const store = useStore();
  const {
    register,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const errorDetails = getObjectValues(errors, `${fieldRootPath}searchAddress`);
  const [addressLookupUrl, setAddressLookupUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [addressID, setAddressID] = useState("");
  const [addressSuggestions, setAddressSuggestions] = useState<AddressSuggestions[]>([]);

  const [isFieldBlurred, setIsFieldBlurred] = useState(false);
  const addressSearchValidate = (value: string) => {
    return isFieldBlurred && value ? "Please enter a valid UK address" : true;
  };
  const { onChange, onBlur, ...restReg } = register(`${fieldRootPath}searchAddress`, {
    required: "Please enter a valid UK address",
    validate: addressSearchValidate,
  });
  const fieldOnBlur = (e: ChangeEvent<HTMLInputElement>) => {
    setIsFieldBlurred(true);
    onBlur(e);
  };
  const fieldOnFocus = () => setIsFieldBlurred(false);

  useLayoutEffect(() => {
    setValue(`${fieldRootPath}searchAddress`, "");
    clearErrors(`${fieldRootPath}searchAddress`);
  }, []);

  const {
    response: addressesResponse,
    status: addressesStatus,
    request: getAddresses,
  } = ValidationAPI<AddressSuggestions[]>(addressLookupUrl);

  const {
    response: addressIDResponse,
    status: addressIDStatus,
    request: getAddressDetails,
  } = ValidationAPI<AddressDetailsApiResponse>(`addresses/${addressID}`);

  useEffect(() => {
    if (addressLookupUrl) {
      const timer = setTimeout(async () => {
        getAddresses();
      }, 500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [addressLookupUrl]);

  useEffect(() => {
    if (addressesStatus.success) {
      if (!!addressesResponse) {
        setAddressSuggestions(addressesResponse);
        setIsLoading(false);
      }
    }
  }, [addressesStatus.success]);

  useEffect(() => {
    if (addressesStatus.error) {
      setAddressSuggestions([]);
      setIsLoading(false);
      setError?.(`${fieldRootPath}searchAddress`, {
        message: "Our address lookup isn't currently working. Please enter address manually",
      });
    }
  }, [addressesStatus.error]);

  useEffect(() => {
    setIsLoading(false);
    if (addressIDStatus.success) {
      if (!!addressIDResponse) {
        const { countryName, ...addressDetails } = addressIDResponse;
        const countryIsoCode = store?.countries?.find(
          (country) => country?.description === countryName,
        )?.name;

        setMultipleValues(
          [
            { name: `${fieldRootPath}addressLine1`, value: addressDetails?.addressLine1 },
            { name: `${fieldRootPath}addressLine2`, value: addressDetails?.addressLine2 },
            { name: `${fieldRootPath}addressLine3`, value: addressDetails?.addressLine3 },
            { name: `${fieldRootPath}city`, value: addressDetails?.city },
            { name: `${fieldRootPath}countryIsoCode`, value: countryIsoCode },
            { name: `${fieldRootPath}county`, value: addressDetails?.county },
            { name: `${fieldRootPath}postCode`, value: addressDetails?.postCode },
          ],
          setValue,
        );
        setShowManualAdd(true);
      }
    }
  }, [addressIDStatus.success]);

  useEffect(() => {
    setIsLoading(false);
    if (addressIDStatus.error) {
      setMultipleValues(
        [
          { name: `${fieldRootPath}addressLine1`, value: defaultAddressDetails?.addressLine1 },
          { name: `${fieldRootPath}addressLine2`, value: defaultAddressDetails?.addressLine2 },
          { name: `${fieldRootPath}addressLine3`, value: defaultAddressDetails?.addressLine3 },
          { name: `${fieldRootPath}city`, value: defaultAddressDetails?.city },
          { name: `${fieldRootPath}countryIsoCode`, value: "GB" },
          { name: `${fieldRootPath}county`, value: defaultAddressDetails?.county },
          { name: `${fieldRootPath}postCode`, value: defaultAddressDetails?.postCode },
        ],
        setValue,
      );

      setError?.(`${fieldRootPath}searchAddress`, {
        message: "Our address lookup isn't currently working. Please enter address manually",
      });
    }
  }, [addressIDStatus.error]);

  const queryChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e?.target;
    clearErrors?.(`${fieldRootPath}searchAddress`);
    if (value.trim()) {
      setAddressLookupUrl(`addresses?text=${value}&limit=100`);
    } else {
      setAddressLookupUrl("");
      setAddressSuggestions([]);
    }
  };

  const onClickSuggestion = async (selectedObj: AddressSuggestions) => {
    setValue(`${fieldRootPath}searchAddress`, selectedObj?.label);
    setIsLoading(true);
    const { id, text, type } = selectedObj;
    if (id && type === "Address") {
      setAddressID(id);
    } else if (id && text) {
      setAddressLookupUrl(`addresses?text=${text}&limit=100&container=${id}`);
    }
  };

  useEffect(() => {
    if (addressID) {
      getAddressDetails();
    }
  }, [addressID]);

  return (
    <FormGroup
      label={label}
      isRequired={!!label}
      mb={"4rem"}
      info={helperText}
      error={errorDetails?.message}
      data-heapid={emptyErrorDataHeapId(errorDetails?.type, "", heapId?.onEmptyError)}
    >
      <AutoCompleteSearch
        suggestions={addressSuggestions}
        queryChangeHandler={(e: ChangeEvent<HTMLInputElement>) => {
          onChange(e);
          queryChangeHandler(e);
        }}
        onClickSuggestion={(obj: unknown) => onClickSuggestion(obj as AddressSuggestions)}
        isError={!!errorDetails}
        onBlur={fieldOnBlur}
        onFocus={fieldOnFocus}
        rightIcon={
          !!errorDetails?.message ? (
            <ErrorIcon color="$fg.system.error_default" />
          ) : isLoading ? (
            <Spinner />
          ) : (
            <SearchIcon boxSize="2.4rem" />
          )
        }
        heapId={{
          onEnter: heapId?.onSearch,
        }}
        {...restReg}
      />
    </FormGroup>
  );
};

export default AddressSearch;
