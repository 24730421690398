import { Box, Show, useDisclosure } from '@chakra-ui/react';
import { MobileStepperProps } from '../../types';
import { MobileStepList } from '../mobile-step-list/mobile-step-list';
import { StepHeader } from '../step-header/step-header';

export const MobileStepper = <T extends string>({
  steps,
  stepOrder,
  currentStep,
  setStep,
  formTitle,
  eyebrowText,
  previousStepDisabledCallback,
  noPreviousStepCallback,
  showBelow,
  notificationAriaLabel,
  eyebrowContent,
  bottomContent,
  ...rest
}: MobileStepperProps<T>) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Show below={showBelow}>
      <Box as="nav" {...rest}>
        <StepHeader
          steps={steps}
          stepOrder={stepOrder}
          currentStep={currentStep}
          onOpen={onOpen}
          setStep={setStep}
          previousStepDisabledCallback={previousStepDisabledCallback}
          noPreviousStepCallback={noPreviousStepCallback}
          notificationAriaLabel={notificationAriaLabel}
        />
        <MobileStepList
          steps={steps}
          stepOrder={stepOrder}
          currentStep={currentStep}
          isOpen={isOpen}
          onClose={onClose}
          setStep={setStep}
          eyebrowText={eyebrowText}
          eyebrowContent={eyebrowContent}
          bottomContent={bottomContent}
          formTitle={formTitle}
        />
      </Box>
    </Show>
  );
};
