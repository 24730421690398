import { defaultAddressDetails } from "src/components/address/manage-address/manageAddress.utils";
import { ErrorResponse } from "src/core/service/Service.types";
import { StoreContextType } from "src/core/store/reference/reference.types";
import { DOBErrorMessage } from "src/shared/applicant/Applicant.enums";
import { getDefaultDescription } from "src/shared/applicant/Applicant.utils";
import { IndividualDetailsType } from "src/shared/company/Company.types";
import { formatDateForBackend, formatDateToDisplay } from "src/shared/company/Company.utils";
import {
  OtherPartiesFormErrorFields,
  OtherPartiesFormValues,
} from "./../BusinessRewardsOtherParties.types";

export const convertOtherPartiesDataIntoBEFormat = (data: OtherPartiesFormValues) => {
  const { otherPartiesHomeAddress, dateOfBirthSCV, nationalities, taxResidencies, ...rest } = data;

  const taxIdentifications = taxResidencies?.filter(
    (tax) => !["GB", ""]?.includes(tax?.taxCountryCode),
  );

  const backEndPatchPayload = {
    individualShareholders: [
      {
        ...rest,
        dateOfBirthSCV: formatDateForBackend(dateOfBirthSCV as string),
        addresses: [
          {
            ...otherPartiesHomeAddress,
            addressType: "HOME_ADDRESS",
            addressStatus: "ACTIVE",
          },
        ],
        nationalities: nationalities?.map((item) => item?.value),
        taxIdentifications: [{ taxCountryCode: "GB", number: "NA" }, ...taxIdentifications],
      },
    ],
  };

  return backEndPatchPayload;
};

export const getOtherPartiesDataInFEFormat = (
  individual: IndividualDetailsType,
  referenceData: StoreContextType,
): OtherPartiesFormValues => {
  const {
    nationalities,
    taxIdentifications,
    dateOfBirthSCV,
    addresses,
    phone,
    emailAddress,
    shareHoldingPercentage,
    ...rest
  } = individual;

  const otherPartyFormValues: OtherPartiesFormValues = {
    ...rest,
    role: rest?.businessIndividualRelations
      ?.map((relation) =>
        getDefaultDescription(relation?.type, referenceData.businessIndividualRelationTypes),
      )
      .join(" / "),
    dateOfBirthSCV: dateOfBirthSCV ? formatDateToDisplay(dateOfBirthSCV) : "",
    nationalities: nationalities?.map((item) => ({ value: item })) as { value: string }[],
    taxResidencies: taxIdentifications?.filter((item) => item?.taxCountryCode !== "GB") || [],
    otherPartiesHomeAddress:
      Array.isArray(addresses) && addresses.length ? addresses[0] : defaultAddressDetails,

    confirmation: "",
    phone: phone || "",
    emailAddress: emailAddress || "",
    shareHoldingPercentage: shareHoldingPercentage || "",
  };

  return otherPartyFormValues;
};

export const getParsedErrorList = (
  otherPartiesError: ErrorResponse<OtherPartiesFormErrorFields>,
) => {
  const errorsList: Record<string, string> = {};

  otherPartiesError?.errors?.forEach((data) => {
    const { field: fieldName, reason } = data;
    const field = fieldName.replace("individualShareholders[0].", "");

    if (field?.includes("taxIdentifications")) {
      const taxResidenciesKeyName = field?.split(".")?.slice(-1);
      const num = field?.split(".")[0]?.replace(/[^0-9]/g, "");
      if (+num > 0) {
        errorsList[`taxResidencies.${+num - 1}.${taxResidenciesKeyName}`] = reason;
      }
    } else if (field?.includes("addresses")) {
      const homeAddressField = field?.replace("addresses[0].", "");
      if (errorsList[`otherPartiesHomeAddress.${homeAddressField}`]) {
        errorsList[`otherPartiesHomeAddress.${homeAddressField}`] += ", " + reason;
      } else {
        errorsList[`otherPartiesHomeAddress.${homeAddressField}`] = reason;
      }
    } else if (field?.includes("nationalities")) {
      const num = field === "nationalities" ? "0" : field?.replace(/[^0-9]/g, "");
      if (num) errorsList[`nationalities.${+num}.value`] = reason;
    } else errorsList[field] = reason;
  });

  return errorsList;
};

export const dobOtherPartiesDataHeapId = (errorMessage: string | undefined) => {
  switch (errorMessage) {
    case DOBErrorMessage.EMPTY_DOB_ERROR:
      return "2otherdob-empty-error";
    case DOBErrorMessage.INVALID_DOB_ERROR:
      return "2otherdob-over-error";
    case DOBErrorMessage.BELOW18_DOB_ERROR:
      return "2otherdob-under-error";
    default:
      return undefined;
  }
};

export const emailOtherPartiesDataHeapId = (errorType: string | undefined) => {
  if (errorType === "required") {
    return "2otheremail-empty-error";
  }

  if (errorType) {
    return "2otheremail-valid-error";
  }

  return undefined;
};

export const phoneNumberOtherPartiesDataHeapId = (errorType: string | undefined) => {
  if (errorType === "required") {
    return "2otherphone-empty-error";
  }

  if (errorType) {
    return "2otherphone-valid-error";
  }

  return undefined;
};
