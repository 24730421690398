import { OrderedList } from '@chakra-ui/react';
import { StepListProps, StepStatus } from '../../types';
import { StepListItem } from '../step-list-item/step-list-item';

export const StepList = <T extends string>({ currentStep, setStep, steps, stepOrder }: StepListProps<T>) => {
  const firstAlertStepIndex = stepOrder.findIndex((key) => steps[key].status === StepStatus.ALERT);

  return (
    <OrderedList m="0">
      {stepOrder.map((stepKey, index) => {
        const { label, status, disabled, ...rest } = steps[stepKey];

        return (
          <StepListItem
            key={stepKey}
            label={label}
            status={status}
            setStep={() => setStep(stepKey)}
            isCurrentStep={stepKey === currentStep}
            disableConnector={index === 0}
            disabled={disabled}
            followsAlertStep={firstAlertStepIndex > -1 && index > firstAlertStepIndex}
            {...rest}
          />
        );
      })}
    </OrderedList>
  );
};
