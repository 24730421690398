import {
  SummaryList,
  SummaryListItem,
  SummaryListItemDescription,
  SummaryListItemTitle,
} from "@allica/ui-react";

export interface SummaryListProps {
  title: string;
  listItems: { title: string; description: string }[];
  onEdit?: () => void;
  heapId?: string;
}

export const SummaryListContainer = ({
  title,
  listItems,
  onEdit,
  // heapId, heapId was originally used to track user interactions with the edit button (requires @allica/ui-react component update)
}: SummaryListProps) => {
  const dataTestId = `${title}-fields-wrapper`.toLowerCase().replace(/ /i, "-");
  const items = listItems.map(({ title, description }) => (
    <SummaryListItem
      key={title}
      title={<SummaryListItemTitle textVariant="body">{title}</SummaryListItemTitle>}
      description={
        <SummaryListItemDescription textVariant="medium">{description}</SummaryListItemDescription>
      }
    />
  ));

  return (
    <SummaryList
      mb={{ base: "$space.section.margin_bottom.base", md: "$space.section.margin_bottom.md" }}
      data-testid={dataTestId}
      title={title}
      onEditClick={onEdit}
    >
      {items}
    </SummaryList>
  );
};
