import { Button, ChevronRightIcon, Heading, Text } from "@allica/ui-react";
import { Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { defaultAddressDetails } from "src/components/address/manage-address/manageAddress.utils";
import { DepositsAPI } from "src/core/service";
import { useStore } from "src/core/store/StoreContext";
import { ApplicationSections as BSApplicationSections } from "src/pages/business-savings/BusinessSavings.types";
import { ApplicationSections as BRApplicationSections } from "src/pages/business-rewards/BusinessRewards.types";
import { formatDateForBackend, formatDateToDisplay } from "src/shared/company/Company.utils";
import {
  ApplicantSection,
  ApplicantUIProps,
  MainApplicantFormErrorFields,
  MainApplicantFormValues,
} from "./Applicant.types";
import { getDefaultDescription } from "./Applicant.utils";
import MainApplicantDetails from "./main-applicant-details/MainApplicantDetails";
import MainApplicantSelect from "./main-applicant-select/MainApplicantSelect";
import { ApplicationStatusResponse } from "src/core/service/deposits-api/DepositsApi.types";

const ApplicantUI = ({
  businessApplicationSections,
  applicationID,
  setShowGenericError,
  updateDataAndStepper,
}: ApplicantUIProps) => {
  const referenceData = useStore();
  const formMethods = useForm<MainApplicantFormValues>({
    defaultValues: {
      nationalities: [{ value: "" }],
    },
  });

  const [showApplicantSelect, setShowApplicantSelect] = useState(true);

  useEffect(() => {
    setShowGenericError(false);
  }, [showApplicantSelect]);

  const connectedIndividuals =
    businessApplicationSections?.companySection?.companiesHouseResponse?.businessProfile
      ?.connectedIndividuals;

  const businessAddresses = businessApplicationSections?.companySection?.addresses;

  const {
    status: saveReqStatus,
    request: saveData,
    error: saveError,
  } = DepositsAPI<ApplicationStatusResponse, MainApplicantFormErrorFields>(
    `applications/businesses/${applicationID}/applicant`,
  );

  useEffect(() => {
    const applicantSection = businessApplicationSections.applicantSection;
    setShowApplicantSelect(!applicantSection?.firstName);
    if (!!applicantSection) {
      const {
        taxIdentifications,
        internationalTradingCountryIsoCodes,
        nationalities,
        addresses,
        dateOfBirthSCV,
        ...others
      } = applicantSection;

      formMethods?.reset({
        ...others,
        role: others?.businessIndividualRelations
          ?.map((relation) =>
            getDefaultDescription(relation?.type, referenceData.businessIndividualRelationTypes),
          )
          .join(" / "),
        dateOfBirthSCV: formatDateToDisplay(dateOfBirthSCV as string),
        countries: internationalTradingCountryIsoCodes?.map((c: string) => ({ value: c })) || [],
        nationalities: nationalities?.map((item) => ({ value: item })) as { value: string }[],
        taxResidencies: taxIdentifications?.filter((item) => item?.taxCountryCode !== "GB"),
        applicantHomeAddress:
          Array.isArray(addresses) && addresses.length ? addresses[0] : defaultAddressDetails,
        confirmation: "",
      });
    }
  }, [businessApplicationSections?.applicantSection]);

  const onSuccess = () => {
    const newData = { ...businessApplicationSections };
    const values = formMethods?.getValues();
    const {
      countries,
      nationalities,
      taxResidencies,
      addresses,
      applicantHomeAddress,
      dateOfBirthSCV,
      ...otherData
    } = values;
    const taxIdentifications = taxResidencies?.filter(
      (c) => !["GB", ""]?.includes(c?.taxCountryCode),
    );

    let newApplicantHomeAddress = {
      ...applicantHomeAddress,
      addressType: "HOME_ADDRESS",
      nodeId: "",
      nodeType: "A",
      addressStatus: "ACTIVE",
    };
    if ("searchAddress" in newApplicantHomeAddress) {
      const { searchAddress, ...otherData } = newApplicantHomeAddress;
      newApplicantHomeAddress = { ...otherData };
    }

    const applicantData = {
      ...otherData,
      dateOfBirthSCV: formatDateForBackend(dateOfBirthSCV as string),
      internationalTradingCountryIsoCodes: countries?.map((country) => country?.value),
      nationalities: nationalities?.map((item) => item?.value),
      taxIdentifications: [{ taxCountryCode: "GB", number: "NA" }, ...taxIdentifications],
      addresses: [newApplicantHomeAddress],
    };

    newData.applicantSection = {
      ...newData?.applicantSection,
      ...applicantData,
    } as ApplicantSection;
    updateDataAndStepper(newData as BSApplicationSections & BRApplicationSections);
  };

  useEffect(() => {
    saveReqStatus.success && onSuccess();
  }, [saveReqStatus.success]);

  useEffect(() => {
    if (!saveReqStatus.error) {
      return;
    }

    if (saveError.code !== "VALIDATION_ERROR") {
      setShowGenericError(true);
      return;
    }

    const addressErrors: Record<string, string> = {};

    saveError?.errors?.forEach((data) => {
      const { field, reason } = data;

      if (field?.includes("taxIdentifications")) {
        if (field.includes("number")) {
          const num = field?.split(".")[0]?.replace(/taxIdentifications\[|\]/g, "");
          if (+num > 0) {
            formMethods?.setError(`taxResidencies.${+num - 1}.number`, { message: reason });
          }
        }
        if (field.includes("taxCountryCode")) {
          const num = field?.split(".")[0]?.replace(/taxIdentifications\[|\]/g, "");
          if (+num > 0) {
            formMethods?.setError(`taxResidencies.${+num - 1}.taxCountryCode`, {
              message: reason,
            });
          }
        }
      } else if (field?.includes("addresses")) {
        const homeAddressField = field?.replace("addresses[0].", "");
        if (addressErrors[`applicantHomeAddress.${homeAddressField}`]) {
          addressErrors[`applicantHomeAddress.${homeAddressField}`] += ", " + reason;
        } else {
          addressErrors[`applicantHomeAddress.${homeAddressField}`] = reason;
        }
      } else if (field?.includes("nationalities")) {
        const num = field?.replace(/nationalities\[|\]/g, "");
        if (num) {
          formMethods?.setError(`nationalities.${+num}.value`, { message: reason });
        }
      } else {
        formMethods?.setError(field, { message: reason });
      }
    });

    for (const errors in addressErrors) {
      formMethods?.setError(`${errors}` as keyof MainApplicantFormValues, {
        message: addressErrors[errors],
      });
    }
  }, [saveReqStatus.error]);

  const onSubmit = (data: MainApplicantFormValues) => {
    if (!showApplicantSelect) {
      const {
        countries,
        mainApplicantSelect,
        nationalities,
        taxResidencies,
        addresses,
        dateOfBirthSCV,
        applicantHomeAddress,
        ...otherData
      } = data;
      const taxIdentifications = taxResidencies?.filter(
        (tax) => !["GB", ""]?.includes(tax?.taxCountryCode),
      );

      let newApplicantHomeAddress = {
        ...applicantHomeAddress,
        addressType: "HOME_ADDRESS",
        nodeId: "",
        nodeType: "A",
        addressStatus: "ACTIVE",
      };
      if ("searchAddress" in newApplicantHomeAddress) {
        const { searchAddress, ...otherData } = newApplicantHomeAddress;
        newApplicantHomeAddress = { ...otherData };
      }

      const payload = {
        ...otherData,
        internationalTradingCountryIsoCodes: countries?.map((country) => country?.value),
        nationalities: nationalities?.map((item) => item?.value),
        taxIdentifications: [{ taxCountryCode: "GB", number: "NA" }, ...taxIdentifications],
        addresses: [newApplicantHomeAddress],
        dateOfBirthSCV: formatDateForBackend(dateOfBirthSCV as string),
        onlineBanking: true,
        isMainContact: true,
      };

      saveData({
        method: "PATCH",
        body: JSON.stringify(payload),
      });
    }
  };

  const onMainApplicantChange = (e: Event) => {
    formMethods?.clearErrors("mainApplicantSelect");
    const { value } = e?.target as HTMLInputElement;

    if (!!connectedIndividuals?.[+value]) {
      const {
        taxIdentifications,
        internationalTradingCountryIsoCodes,
        nationalities,
        addresses,
        dateOfBirthSCV,
        ...others
      } = connectedIndividuals?.[+value];

      formMethods?.reset({
        ...others,
        role: others?.businessIndividualRelations
          ?.map((relation) =>
            getDefaultDescription(relation?.type, referenceData.businessIndividualRelationTypes),
          )
          .join(" / "),
        dateOfBirthSCV: formatDateToDisplay(dateOfBirthSCV as string),

        countries: internationalTradingCountryIsoCodes?.map((c: string) => ({ value: c })) || [],
        nationalities: nationalities?.map((item) => ({ value: item })) as { value: string }[],
        taxResidencies: taxIdentifications?.filter((item) => item?.taxCountryCode !== "GB"),
        addresses: [...businessAddresses],
      });
    }

    setShowApplicantSelect(false);
  };

  return (
    <form noValidate onSubmit={formMethods?.handleSubmit(onSubmit)}>
      <Heading size="h1" as="h1" mb="1.6rem">
        Main applicant details
      </Heading>
      <Text color="neutral.600" textStyle="body-02-regular" mb="6.4rem">
        Choose the main applicant from the list of Company Officers recorded at Companies House.
      </Text>
      <FormProvider {...formMethods}>
        {!showApplicantSelect ? (
          <>
            <Box>
              <Button variant="text" onClick={() => setShowApplicantSelect(true)}>
                Choose a different main applicant
              </Button>
            </Box>
            <MainApplicantDetails />
          </>
        ) : (
          <MainApplicantSelect
            mainApplicantOptions={Array?.isArray(connectedIndividuals) ? connectedIndividuals : []}
            onChangeHandler={onMainApplicantChange}
          />
        )}
      </FormProvider>

      <Button
        isLoading={saveReqStatus?.loading}
        loadingText="Save and continue"
        spinnerPlacement="end"
        type="submit"
        mt="8rem"
        float="right"
        padding="2.4rem 3.2rem"
        rightIcon={<ChevronRightIcon boxSize="2.4rem" />}
        data-heapid="save-continue-button"
      >
        Save and continue
      </Button>
    </form>
  );
};

export default ApplicantUI;
