import { Text } from "@allica/ui-react";
import { Modal } from "src/components/modal/Modal";

interface NoMatchModalProps {
  isOpen: boolean;
  onClose: () => void;
  continueAction: () => void;
  continueActionLoading: boolean;
}

export const NoMatchModal = ({
  isOpen,
  onClose,
  continueAction,
  continueActionLoading,
}: NoMatchModalProps) => {
  return (
    <Modal
      headerTitle="Recipient name doesn't match"
      primaryButtonText="Update account details"
      secondaryButtonText="Continue without changing"
      isOpen={isOpen}
      onClose={onClose}
      primaryAction={onClose}
      secondaryAction={continueAction}
      secondaryButtonLoading={continueActionLoading}
      variant="error"
      closeOnOverlayClick={false}
      heapId={{
        secondaryAction: "continue-nochange-button",
        primaryAction: "update-account-button",
      }}
    >
      <Text as="p" textStyle="body-02-regular" color="denim.900">
        The name entered isn’t the name registered on the bank account. <br />
        <br /> If you continue with these details your application will be reviewed by our Risk
        team.
      </Text>
    </Modal>
  );
};
