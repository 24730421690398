import { useState } from "react";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  OrderedList,
} from "@chakra-ui/react";
import { MobileStepListItem } from "../mobile-step-list-item/mobile-step-list-item";
import { MobileStepListProps } from "../../types";
import { Heading, Text } from "@allica/ui-react";

export const MobileStepList = <T extends string>({
  isOpen,
  onClose,
  eyebrowText,
  eyebrowContent,
  bottomContent,
  formTitle,
  steps,
  stepOrder,
  currentStep,
  setStep,
}: MobileStepListProps<T>) => {
  const [startY, setStartY] = useState<number>(0);
  const [endY, setEndY] = useState<number>(0);
  const [headerCursor, setHeaderCursor] = useState<"grab" | "grabbing">("grab");
  const [drawerHeight, setDrawerHeight] = useState<number>(308);

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    setStartY(e.clientY);
    setHeaderCursor("grabbing");
  };

  const handleMouseUp = (e: React.MouseEvent) => {
    setHeaderCursor("grab");
    setStartY(0);
    setDrawerHeight(308);
    if (startY > 0 && e.clientY - startY > 75) onClose();
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (startY > 0) {
      const totalMovement = startY - e.clientY;

      if (totalMovement < 0) {
        const newDrawerHeight = 308 + totalMovement;
        setDrawerHeight(newDrawerHeight);

        if (newDrawerHeight < 200) {
          onClose();
          setDrawerHeight(308);
          setStartY(0);
          setHeaderCursor("grab");
        }
      }
    }
  };

  const handleMouseLeave = () => setStartY(0);
  const handleTouchStart = (e: React.TouchEvent) => setStartY(e.targetTouches[0].clientY);

  const handleTouchMove = (e: React.TouchEvent) => {
    const totalMovement = startY - e.targetTouches[0].clientY;

    if (totalMovement < 0) {
      const newDrawerHeight = 308 + totalMovement;
      setDrawerHeight(newDrawerHeight);
      setEndY(e.targetTouches[0].clientY);

      if (newDrawerHeight < 200) {
        onClose();
        setDrawerHeight(308);
      }
    }
  };

  const handleTouchEnd = () => {
    if (endY - startY > 75) onClose();
    setDrawerHeight(308);
  };

  return (
    <Drawer placement="bottom" isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent borderTopRadius="0.8rem">
        <Box
          data-testid="drawer_header"
          as="header"
          cursor={headerCursor}
          borderTopRadius="0.8rem"
          p="0.8rem 2.4rem 1.6rem"
          borderBottom="0.1rem solid"
          borderColor="$border.divider"
          minHeight="11.2rem"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
        >
          <Box as="nav">
            <Box
              mx="auto"
              h="0.4rem"
              w="4.8rem"
              borderRadius="10rem"
              backgroundColor="$bg.tertiary"
            />
            <Text as="p" mt="3.2rem" color="$fg.tertiary" textStyle="body-02-regular">
              {eyebrowText}
            </Text>
            {eyebrowContent}
            <Heading mt="0.4rem" color="$fg.primary" size="h2">
              {formTitle}
            </Heading>
          </Box>
        </Box>
        <DrawerBody p="0" maxH={drawerHeight} overflowY="auto" overflowX="hidden">
          <OrderedList m="0">
            {stepOrder.map((stepKey) => {
              const { label, status, disabled } = steps[stepKey];
              return (
                <MobileStepListItem
                  key={stepKey}
                  stepKey={stepKey}
                  label={label}
                  disabled={disabled}
                  status={status}
                  currentStep={currentStep}
                  setStep={() => setStep(stepKey)}
                  onClose={onClose}
                />
              );
            })}
          </OrderedList>
          {bottomContent}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
