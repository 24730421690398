import { LoadingSpinner } from "@allica/ui-react";
import { Flex, Portal } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

interface OverlayProps {
  spinner?: boolean;
  text?: string;
}

export const Overlay = ({ spinner, text, children }: PropsWithChildren<OverlayProps>) => {
  return (
    <Portal>
      <Flex
        position="absolute"
        top="-7.2rem"
        zIndex={9999}
        backgroundColor="$fg.system.gray_active"
        h="100vh"
        w="100%"
        opacity="80%"
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        {spinner && <LoadingSpinner loadingMessage={text || "loading, please wait"} />}
        {children}
      </Flex>
    </Portal>
  );
};
