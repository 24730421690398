import { Component, ReactNode, ErrorInfo } from "react";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { reactPlugin } from "../app/ApplicationInsights";
import BrandLayout from "src/components/brand-layout/BrandLayout";
import { Card, GridItem } from "@chakra-ui/react";
import { Button, Heading, Text } from "@allica/ui-react";
import Header from "src/components/header/Header";

interface PropsInterface {
  children?: ReactNode;
}

interface StateInterface {
  hasError: Boolean;
  error?: Error | undefined | null;
  info?: ErrorInfo;
}

class ErrorBoundary extends Component<PropsInterface, StateInterface> {
  state = { hasError: false };

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ hasError: true, error, info });
    reactPlugin.trackException({
      error: error,
      exception: error,
      severityLevel: SeverityLevel.Error,
      properties: info,
    });
  }

  refreshPage = () => {
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <>
          <Header
            setExitByStepper={() => {}}
            setShowExitApplicationModal={() => {}}
            applicationStarted={false}
            applicationComplete={false}
          />
          <BrandLayout>
            <GridItem
              as="section"
              colStart={{ base: 1, sm: 2, lg: 3, xl: 4 }}
              colSpan={{ base: 4, sm: 6, lg: 8, xl: 6 }}
            >
              <Heading color="neutral.0" size="h1" as="h1" mt="6.4rem">
                Oops, something went wrong
              </Heading>
              <Card p="2.4rem" mt="4rem" boxShadow="none" borderRadius="0.4rem">
                <Text textStyle="body-02-regular" color="denim.900">
                  We apologise for the inconvenience, it looks like there was an issue.
                </Text>
                <br></br>
                <Text textStyle="body-02-regular" color="denim.900">
                  Please hit the retry button again, and if the issue persists, please contact our
                  support team for further assistance.
                </Text>
                <Button
                  mt="3.2rem"
                  maxW={{ base: "auto", sm: "10.4rem" }}
                  onClick={this.refreshPage}
                >
                  Retry
                </Button>
              </Card>
              <Heading size="h2" as="h2" mt="6.4rem">
                Customer support
              </Heading>
              <Text mt="1.6rem" textStyle="body-02-medium" color="darkdenim.500">
                Phone:
                <Text as="span" textStyle="body-02-regular" color="neutral.600">
                  {" 0330 094 3333"}
                </Text>
              </Text>
              <Text textStyle="body-02-medium" color="darkdenim.500">
                Monday, Tuesday, Thursday and Friday:
                <Text as="span" textStyle="body-02-regular" color="neutral.600">
                  {" 9.00am to 5.00pm"}
                </Text>
              </Text>
              <Text textStyle="body-02-medium" color="darkdenim.500">
                Wednesday:
                <Text as="span" textStyle="body-02-regular" color="neutral.600">
                  {" 9.30am to 5.00pm"}
                </Text>
              </Text>
              <Text textStyle="body-02-medium" color="darkdenim.500">
                {"Email: "}
                <Text
                  as="a"
                  href="mailto:customer.services@allica.bank"
                  textStyle="body-02-regular"
                  color="$fg.interactive.default"
                  _hover={{ textDecoration: "underline", cursor: "pointer" }}
                >
                  customer.services@allica.bank
                </Text>
              </Text>
            </GridItem>
          </BrandLayout>
        </>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
