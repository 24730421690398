import { BoxProps, Flex } from "@chakra-ui/react";
import { StepperProps } from "../../types";
import { StepList } from "../step-list/step-list";
import { handlePrevious } from "../../helpers";
import { ChevronLeftIcon, Heading, Text } from "@allica/ui-react";

/**
 * @deprecated Use Steps component instead
 */
export const Stepper = <T extends string>({
  currentStep,
  setStep,
  steps,
  stepOrder,
  eyebrowText,
  eyebrowContent,
  formTitle,
  previousStepDisabledCallback,
  noPreviousStepCallback,
  showPreviousStepButton = true,
  backgroundColor = "$bg.secondary",
  bottomContent,
  ...rest
}: StepperProps<T> & BoxProps) => {
  const handleClick = () =>
    handlePrevious({
      steps,
      stepOrder,
      setStep,
      currentStep,
      previousStepDisabledCallback,
      noPreviousStepCallback,
    });

  return (
    <Flex
      direction="column"
      justifyContent="space-between"
      as="nav"
      backgroundColor={backgroundColor}
      position="absolute"
      top="0"
      left="0"
      bottom="0"
      w="32rem"
      p="6.4rem 4rem 0 4rem"
      overflowY="auto"
      overflowX="hidden"
      {...rest}
    >
      <Flex direction="column">
        {eyebrowText ? (
          <Text as="p" color="$fg.tertiary" textStyle="body-02-regular">
            {eyebrowText}
          </Text>
        ) : null}
        {eyebrowContent}
        <Heading size="h2" color="$fg.primary" mb="4.8rem" mt="1.2rem">
          {formTitle}
        </Heading>
        <StepList steps={steps} stepOrder={stepOrder} currentStep={currentStep} setStep={setStep} />
      </Flex>
      {bottomContent}
      {showPreviousStepButton && (
        <Flex
          as="button"
          onClick={handleClick}
          alignItems="center"
          my="4rem"
          color="$fg.interactive.default"
          textStyle="body-02-regular"
        >
          <ChevronLeftIcon mr="0.8rem" boxSize="2.4rem" />
          Previous step
        </Flex>
      )}
    </Flex>
  );
};
