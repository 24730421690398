import { ErrorIcon } from "@allica/ui-react";
import { Spinner } from "@chakra-ui/react";
import { ChangeEvent, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { SearchIcon } from "src/components/icon";
import { FormGroup } from "src/components/input-set/FormGroup";
import { ValidationAPI } from "src/core/service";
import { useStore } from "src/core/store/StoreContext";
import { AutoCompleteSearch } from "../../../../components/autocomplete";
import { defaultAddressDetails } from "./Address";
import {
  AddressDetailsTypes,
  AddressSearchProps,
  AddressSuggestions,
  FormDataValues,
} from "./Address.types";

const AddressSearch = ({ setAddressDetails, setShowManualAdd }: AddressSearchProps) => {
  const store = useStore();
  const {
    register,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext<FormDataValues>();
  const [addressLookupUrl, setAddressLookupUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [addressID, setAddressID] = useState("");
  const [addressSuggestions, setAddressSuggestions] = useState<AddressSuggestions[]>([]);

  const { onChange, ...restReg } = register("homeAddress", {
    required: "Please enter a valid UK address",
  });

  const {
    response: addressesResponse,
    status: addressesStatus,
    request: getAddresses,
  } = ValidationAPI<AddressSuggestions[]>(addressLookupUrl);

  const {
    response: addressIDResponse,
    status: addressIDStatus,
    request: getAddressDetails,
  } = ValidationAPI<AddressDetailsTypes>(`addresses/${addressID}`);

  useEffect(() => {
    if (addressLookupUrl) {
      const timer = setTimeout(async () => {
        getAddresses();
      }, 500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [addressLookupUrl]);

  useEffect(() => {
    if (addressesStatus.success) {
      if (!!addressesResponse) {
        setAddressSuggestions(addressesResponse);
        setIsLoading(false);
      }
    }
  }, [addressesStatus.success]);

  useEffect(() => {
    if (addressesStatus.error) {
      setAddressSuggestions([]);
      setIsLoading(false);
      setError?.("homeAddress", {
        message: "Our address lookup isn't currently working. Please enter address manually",
      });
    }
  }, [addressesStatus.error]);

  useEffect(() => {
    setIsLoading(false);
    if (addressIDStatus.success) {
      if (!!addressIDResponse) {
        const { countryName, ...addressDetails } = addressIDResponse;
        const countryIsoCode = store?.countries?.find(
          (country) => country?.description === countryName,
        )?.name;
        setAddressDetails({ ...addressDetails, countryIsoCode: countryIsoCode || "GB" });
        setShowManualAdd(true);
      }
    }
  }, [addressIDStatus.success]);

  useEffect(() => {
    setIsLoading(false);
    if (addressIDStatus.error) {
      setAddressDetails(defaultAddressDetails);
      setError?.("homeAddress", {
        message: "Our address lookup isn't currently working. Please enter address manually",
      });
    }
  }, [addressIDStatus.error]);

  const queryChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e?.target;
    clearErrors?.("homeAddress");
    if (value.trim()) {
      setAddressLookupUrl(`addresses?text=${value}&limit=100`);
    } else {
      setAddressLookupUrl("");
      setAddressSuggestions([]);
    }
  };

  const onClickSuggestion = async (selectedObj: AddressSuggestions) => {
    setValue("homeAddress", selectedObj?.label);
    setIsLoading(true);
    const { id, text, type } = selectedObj;
    if (id && type === "Address") {
      setAddressID(id);
    } else if (id && text) {
      setAddressLookupUrl(`addresses?text=${text}&limit=100&container=${id}`);
    }
  };

  useEffect(() => {
    if (addressID) {
      getAddressDetails();
    }
  }, [addressID]);

  return (
    <FormGroup
      label="Home Address"
      isRequired
      mb="4rem"
      info="Start typing your address or postcode"
      error={errors?.homeAddress?.message}
    >
      <AutoCompleteSearch
        suggestions={addressSuggestions}
        queryChangeHandler={(e: ChangeEvent<HTMLInputElement>) => {
          onChange(e);
          queryChangeHandler(e);
        }}
        onClickSuggestion={(obj: unknown) => onClickSuggestion(obj as AddressSuggestions)}
        isError={!!errors?.homeAddress}
        rightIcon={
          !!errors?.homeAddress ? (
            <ErrorIcon color="$fg.system.error_default" />
          ) : isLoading ? (
            <Spinner />
          ) : (
            <SearchIcon boxSize="2.4rem" />
          )
        }
        {...restReg}
      />
    </FormGroup>
  );
};

export default AddressSearch;
