import { Text } from "@allica/ui-react";
import { HStack } from "@chakra-ui/react";
import { ConfirmIcon } from "../../../icon";
import { CheckListProps } from "./CheckList.types";

export const CheckList = ({ done, text }: CheckListProps) => (
  <HStack>
    <ConfirmIcon
      color={done ? "$fg.system.success" : "$fg.inverse.viewonly"}
      boxSize={"1.75rem"}
      fill={done ? "neutral.0" : "$fg.inverse.viewonly"}
      mr="0.4rem"
    />
    <Text color={done ? "$fg.system.success" : "neutral.600"}>{text}</Text>
  </HStack>
);
