import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Text, Heading, Button, CopyOutlineIcon, PageLayout } from "@allica/ui-react";
import { GridItem, Card, Flex, Link, Box } from "@chakra-ui/react";

import environment from "src/environments/environment";
import { appInsights } from "src/core/app/ApplicationInsights";
import { DepositsAPI } from "src/core/service";
import { usePersonalContext } from "src/pages/personal-savings/context/PersonalContext";
import {
  ApplicationStatusResponse,
  StageOptions,
  StatusOptions,
} from "src/core/service/deposits-api/DepositsApi.types";
import { FooterInformation } from "src/shared/outcome/footerInformation/FooterInformation";

import styles from "../../../../../shared/outcome/Outcome.module.css";

const DepositRefer = () => {
  const { personalSavingData, setPersonalSavingData } = usePersonalContext();
  const [submitStep, setSubmitStep] = useState<StageOptions | null>(null);
  const params = useParams();
  const userApplicationID =
    personalSavingData?.cobApplicationId || personalSavingData?.applicationID;

  const {
    status: accountOpenStatus,
    response: accountOpenResponse,
    request: accountOpenStatusRequest,
  } = DepositsAPI<ApplicationStatusResponse>(
    `applications/${personalSavingData.applicationID}/status-info`,
  );

  const setComplete = () => {
    const newPsData = { ...personalSavingData };
    newPsData.individualApplicationSections.outcome = { success: true };
    setPersonalSavingData(newPsData);

    appInsights.trackEvent({ name: `outcome-success` });
    appInsights.trackEvent({ name: `personal-deposits-${params.product}-completed` });
  };

  const trackRefer = () => {
    appInsights.trackEvent({ name: `outcome-refer` });
    appInsights.trackEvent({ name: `personal-deposits-${params.product}-completed` });
  };

  useEffect(() => {
    if (accountOpenStatus.success) {
      if (
        accountOpenResponse.applicationStage === StageOptions.RISK_CHECK_IN_PROGRESS ||
        accountOpenResponse.applicationStage === StageOptions.SPLITTING
      )
        setTimeout(accountOpenStatusRequest, 2500);
      else if (accountOpenResponse.applicationStatus === StatusOptions.COMPLETED) setComplete();
      else trackRefer(); //IN_REVIEW, PROCESSING_ERROR
      setSubmitStep(accountOpenResponse.applicationStage);
    }
    if (accountOpenStatus.error) {
      setSubmitStep(StageOptions.PROCESSING_ERROR);
    }
  }, [accountOpenStatus]);

  useEffect(() => {
    if (submitStep) appInsights.trackEvent({ name: submitStep.toLowerCase().replace(/_/g, "-") });
  }, [submitStep]);

  useEffect(() => {
    accountOpenStatusRequest();
  }, []);

  return (
    <>
      <Box className={styles.outcomeHeader}>
        <PageLayout
          paddingBottom="3.6rem"
          paddingTop={{ base: "23.5rem", sm: "40rem", md: "8.8rem", xl: "4.8rem" }}
        >
          <GridItem
            colStart={{ base: 1, sm: 1, md: 1, lg: 1 }}
            colSpan={{ base: 4, sm: 8, md: 5, lg: 6 }}
            padding={0}
          >
            <Heading
              color="neutral.0"
              fontSize={{ base: "4rem", md: "4.8rem" }}
              lineHeight={{ base: "4.8rem", md: "5.6rem" }}
              textAlign={{ base: "center", md: "left" }}
            >
              Congratulations! <br />
              You’re all done
            </Heading>
          </GridItem>
        </PageLayout>
      </Box>
      <Box className={styles.outcomeImage}>
        <PageLayout paddingTop={{ base: "2rem", md: "3.6rem" }}>
          <GridItem
            as="section"
            colStart={{ base: 1, sm: 1, md: 1, lg: 1 }}
            colSpan={{ base: 4, sm: 8, md: 5, lg: 6 }}
          >
            <Heading
              color="darkdenim.500"
              fontSize={{ base: "2rem", md: "2.4rem" }}
              lineHeight={{ base: "2.8rem", md: "3.2rem" }}
            >
              What happens next?
            </Heading>
            <Card p="2.4rem" mt="2.4rem" boxShadow="none" borderRadius="0.4rem">
              <Text color="denim.900" textStyle="body-01-regular">
                Thank you for your application. We'll process your data and be in touch soon.
                <br />
                <br />
              </Text>
              <Text color="darkdenim.500" textStyle="body-02-regular">
                Application reference number:
              </Text>
              <Flex
                mt="2.4rem"
                p="3.2rem"
                backgroundColor="$bg.base"
                justifyContent="space-between"
              >
                <Text textStyle="body-02-medium" color="darkdenim.500">
                  {userApplicationID}
                </Text>
                <Link
                  data-testid="copyLink"
                  onClick={() => {
                    navigator.clipboard.writeText(userApplicationID);
                  }}
                >
                  <CopyOutlineIcon boxSize="2.4rem" color="$fg.interactive.default" />
                </Link>
              </Flex>
            </Card>

            <Flex justifyContent="end" mt="6.4rem">
              <Button onClick={() => window.open(environment.ui.allicaPortal)}>
                Back to website
              </Button>
            </Flex>

            <Box mt="6.4rem">
              <FooterInformation />
            </Box>
          </GridItem>
        </PageLayout>
      </Box>
    </>
  );
};

export default DepositRefer;
