import { Flex } from '@chakra-ui/react';
import { Step } from '../../step/step';
import { MobileStepListItemProps, StepStatus } from '../../types';

export const MobileStepListItem = <T extends string>({
  label,
  status,
  setStep,
  stepKey,
  currentStep,
  disabled,
  onClose,
}: MobileStepListItemProps<T>) => {
  const canAction = !disabled && StepStatus.INACTIVE !== status;

  const handleCallback = () => {
    if (canAction) {
      setStep(stepKey);
      onClose();
    }
  };

  return (
    <Flex
      direction="column"
      justifyContent="center"
      borderBottom="0.1rem solid"
      borderColor="$border.divider"
      boxSizing="border-box"
      h="5.6rem"
      pl="2.4rem"
      onClick={handleCallback}
      cursor={canAction ? 'pointer' : 'auto'}
    >
      <Step
        key={stepKey}
        label={label}
        status={status}
        setStep={handleCallback}
        isCurrentStep={stepKey === currentStep}
        disabled={disabled}
        disableConnector
      />
    </Flex>
  );
};
