import { useEffect } from "react";
import { appInsights } from "src/core/app/ApplicationInsights";
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Text,
  AlertIcon,
  AlertActions,
  AlertAction,
  Button,
} from "@allica/ui-react";
import { Box } from "@chakra-ui/react";
import { ErrorBannerProps } from "./ErrorBanner.types";

const ErrorBanner = ({
  title,
  description,
  trackException,
  descriptionCallbacks,
  titleCallback,
  trackEvent,
  ...styleProps
}: ErrorBannerProps) => {
  useEffect(() => {
    trackException && appInsights.trackException(trackException);
    trackEvent && appInsights.trackEvent(trackEvent);
  }, []);

  return (
    <Alert status="error" mb={{ base: "3.2rem", md: "6.4rem" }} {...styleProps}>
      <AlertIcon />
      <Box>
        <AlertTitle>
          {title}
          {titleCallback && (
            <>
              <br />
              <Text
                mt="0.8rem"
                as="a"
                cursor="pointer"
                onClick={titleCallback.method}
                textDecoration="underline"
              >
                {titleCallback.label}
              </Text>
            </>
          )}
        </AlertTitle>
        {description && <AlertDescription>{description}</AlertDescription>}
        {descriptionCallbacks && (
          <AlertActions>
            {descriptionCallbacks.map((descriptionCallback) => (
              <AlertAction key={descriptionCallback.label}>
                <Button
                  key={descriptionCallback.label}
                  variant="text"
                  onClick={descriptionCallback.method}
                  data-heapid={descriptionCallback.heapId}
                >
                  {descriptionCallback.label}
                </Button>
              </AlertAction>
            ))}
          </AlertActions>
        )}
      </Box>
    </Alert>
  );
};

export default ErrorBanner;
