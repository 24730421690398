import { Step, Steps } from "@allica/ui-react";
import { Dispatch, ReactNode, SetStateAction } from "react";
import {
  CompanySectionType,
  IndividualDetailsType,
  UpdateOtherPartiesStage,
} from "src/shared/company/Company.types";
import { ApplicantSection } from "src/shared/applicant/Applicant.types";
import {
  ParsedBCAConfig,
  Question,
} from "./stages/account-activity/BusinessRewardsAccountActivity.types";
import { OtherPartiesFormValues } from "./stages/other-parties/BusinessRewardsOtherParties.types";

export enum StageMVP {
  SIGN_UP = "sign-up",
  VERIFY = "verify",
  BUSINESS = "business",
  APPLICANT = "applicant",
  SUMMARY = "summary",
}

export enum Stage {
  SIGN_UP = "sign-up",
  VERIFY = "verify",
  BUSINESS = "business",
  APPLICANT = "applicant",
  PARTIES = "parties",
  DEPOSIT = "deposit",
  ACCOUNT = "account",
  DOCUMENT = "document",
  SUMMARY = "summary",
}

export enum SubmittedStages {
  COMPLETED = "COMPLETED",
  READY_TO_REVIEW = "READY_TO_REVIEW",
  SPLITTING = "SPLITTING",
  RISK_CHECK_IN_PROGRESS = "RISK_CHECK_IN_PROGRESS",
  PROCESSING_ERROR = "PROCESSING_ERROR",
}

export enum ApplicationStatuses {
  STARTED = "STARTED",
  COMPLETED = "COMPLETED",
  REVIEW = "REVIEW",
  FAIL = "FAIL",
}

export type AlertStage = Stage.APPLICANT | Stage.PARTIES;

type ApplicationSteps = keyof typeof Stage;
export type CurrentApplicationStage = ApplicationSteps | keyof typeof SubmittedStages;
export type ApplicationStatusType = keyof typeof ApplicationStatuses;

export type UpdateStepperConfigStage = { stage: Stage; value: Partial<Step> };

export type UpdateStepperConfig = (
  stages: UpdateStepperConfigStage[],
  disableCompleteOverride?: boolean,
) => void;

export interface OtherParties {
  firstName: string;
  lastName: string;
  role: string;
}

export interface ApplicationSections {
  signUpSection: {
    emailAddress: string;
    phoneNumber: string;
    password?: string;
    domain: "BRA";
  };
  verifySection?: {
    noOfSecurityCodeSent: number;
  };
  companySection: CompanySectionType;
  applicantSection: ApplicantSection | null;
  // FeatureFlag.OTHER_PARTIES
  otherPartiesSection: OtherPartiesFormValues | null;
  // temporarily optional START (FeatureFlag.BRA_PHASE_TWO)
  depositSection?: {
    depositAmount: string;
    depositDuration: string;
  };
  accountSection?: {
    purposeOfAccount: string[];
    monthlyPaymentsIn: string;
    sourceOfFunds: string[];
  };
  documentSection?: {
    terms: boolean;
    email?: boolean;
    sms?: boolean;
    phone?: boolean;
    mail?: boolean;
  };
  // temporarily optional END (FeatureFlag.BRA_PHASE_TWO)
  summarySection: {
    detailsVerified: boolean;
  };
  outcome: {
    success: boolean;
  };
}

export type BEApplicationStage =
  | Exclude<CurrentApplicationStage, "PARTIES" | "BUSINESS">
  | "SHAREHOLDER"
  | "COMPANY"
  | null;

export interface BusinessRewardsApiType
  extends Omit<
    ApplicationSections,
    "accountSection" | "signUpSection" | "otherPartiesSection" | "verifySection"
  > {
  currentApplicationStage: BEApplicationStage;
  cobApplicationId: string;
  signUpSection: {
    emailAddress: string;
    phoneNumber: string;
    productReference: string | null;
    productIssueId: string | null;
    domain: "BRA";
    utmSource: null;
  };
  accountSection: {
    name: string | null;
    sortCode: string | null;
    accountNumber: string | null;
    copResponse: string | null;
    answers: null;
    domain: string | null;
    fullAccountQuestionnaireDetails: {
      questions: Question[];
      tcsVersion: string;
    };
  };
  shareholdersSection: {
    connectedIndividuals: IndividualDetailsType[];
    connectedBusinesses: null;
  };
}

export interface BusinessRewardsDataTypes {
  applicationID: string;
  currentApplicationStage: CurrentApplicationStage | null;
  cobApplicationId: string;
  businessRewardsApplicationSections: ApplicationSections;
  questions: ParsedBCAConfig | null;
}

export interface BusinessRewardsContextType {
  businessRewardsData: BusinessRewardsDataTypes;
  setBusinessRewardsData: Dispatch<React.SetStateAction<BusinessRewardsDataTypes>>;
  currentStage: Stage | null;
  setCurrentStage: Dispatch<SetStateAction<Stage | null>>;
  stepperConfig: Steps<Stage>;
  updateStepperConfig: UpdateStepperConfig;
  stepOrder: Stage[];
  updateOtherPartiesStage: UpdateOtherPartiesStage;
  showGenericError: boolean;
  setShowGenericError: Dispatch<React.SetStateAction<boolean>>;
  alertStages: AlertStage[];
  setAlertStages: Dispatch<React.SetStateAction<AlertStage[]>>;
  isBusinessRewardsDataLoading: boolean;
}

export type BusinessRewardsContextProviderType = {
  children?: ReactNode;
  applicationID: string;
  applicationIDStatus: { loading: boolean; success: boolean };
};
