import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Button, ChevronRightIcon, Heading, StepStatus, Text } from "@allica/ui-react";
import { Box } from "@chakra-ui/react";

import { FormDataValues } from "./Address.types";
import AddressSearch from "./AddressSearch";
import ManualAddress from "./ManualAddress";
import { SearchIcon } from "src/components/icon";
import { DepositsAPI } from "src/core/service";
import { usePersonalContext } from "../../context/PersonalContext";
import { Stage } from "../../PersonalSavings.types";

export const defaultAddressDetails = {
  addressLine1: "",
  addressLine2: "",
  addressLine3: "",
  city: "",
  county: "",
  postCode: "",
  countryIsoCode: "GB",
};

const Address = () => {
  const {
    stepperConfig,
    personalSavingData,
    setCurrentStage,
    setStepperConfig,
    setPersonalSavingData,
    setShowGenericError,
  } = usePersonalContext();
  const initialAddressData =
    personalSavingData?.individualApplicationSections?.addressSection || defaultAddressDetails;

  const methods = useForm<FormDataValues>();
  const { handleSubmit, setError, getValues } = methods;

  const [addressDetails, setAddressDetails] = useState(initialAddressData);
  const [showManualAdd, setShowManualAdd] = useState(
    stepperConfig[Stage.ADDRESS].status === StepStatus.COMPLETE,
  );

  const {
    status: addressStatus,
    request: saveAddress,
    error: addressError,
  } = DepositsAPI(`applications/individuals/${personalSavingData?.applicationID}/address`);

  const onSubmit = (data: FormDataValues) => {
    setShowGenericError(false);
    if (!showManualAdd) {
      setError("homeAddress", { message: "Please enter a valid UK address" });
    } else if (personalSavingData?.applicationID) {
      const payload = {
        addressLine1: data?.addressLine1,
        addressLine2: data?.addressLine2,
        addressLine3: data?.addressLine3,
        city: data?.city,
        county: data?.county,
        postCode: data?.postCode,
        countryIsoCode: data?.countryIsoCode,
      };
      saveAddress({
        method: "PATCH",
        body: JSON.stringify(payload),
      });
    }
  };

  useEffect(() => {
    methods.reset(addressDetails);
  }, [addressDetails]);

  useEffect(() => {
    if (addressStatus.success) {
      const newPersonalSavingData = { ...personalSavingData };
      const { homeAddress, ...addressData } = getValues();
      newPersonalSavingData.individualApplicationSections.addressSection = { ...addressData };
      setPersonalSavingData(newPersonalSavingData);
      const newState = { ...stepperConfig };
      newState[Stage.ADDRESS].status = StepStatus.COMPLETE;
      if (newState[Stage.DEPOSIT].status !== StepStatus.COMPLETE) {
        newState[Stage.DEPOSIT].status = StepStatus.INCOMPLETE;
      }
      setStepperConfig(newState);
      setCurrentStage(Stage.DEPOSIT);
    }
  }, [addressStatus.success]);

  useEffect(() => {
    if (addressStatus.error) {
      if (addressError.code === "VALIDATION_ERROR") {
        addressError?.errors?.forEach((data) => {
          const { field, reason } = data;
          setError(field as keyof FormDataValues, { message: reason });
        });
      } else {
        setShowGenericError(true);
      }
    }
  }, [addressStatus.error]);

  return (
    <>
      <Heading size="h1" as="h1" mb="1.6rem">
        Your address
      </Heading>
      <Text textStyle="body-02-regular" color="neutral.600" mb="6.4rem">
        Add the address that matches to the account you will deposit money from.
      </Text>

      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        {!showManualAdd ? (
          <>
            <FormProvider {...methods}>
              <AddressSearch
                setAddressDetails={setAddressDetails}
                setShowManualAdd={setShowManualAdd}
              />
            </FormProvider>
            <Box mb="8rem" mt="4rem">
              <Button
                variant="text"
                onClick={() => {
                  setShowManualAdd(!showManualAdd);
                }}
              >
                Enter address manually
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Box mb="3.2rem">
              <Button
                variant="text"
                leftIcon={<SearchIcon boxSize="2.4rem" />}
                iconSpacing="0.8rem"
                onClick={() => {
                  setShowManualAdd(!showManualAdd);
                }}
              >
                Search for a new address
              </Button>
            </Box>
            <FormProvider {...methods}>
              <ManualAddress addressDetails={addressDetails} />
            </FormProvider>
          </>
        )}

        <Button
          isLoading={addressStatus?.loading}
          loadingText="Save and continue"
          spinnerPlacement="end"
          type="submit"
          float="right"
          padding="2.4rem 3.2rem"
          rightIcon={<ChevronRightIcon boxSize="2.4rem" />}
        >
          Save and continue
        </Button>
      </form>
    </>
  );
};

export default Address;
