import { Steps, StepStatus } from '../types';

interface HandlePreviousArgs<T extends string> {
  steps: Steps<T>;
  stepOrder: T[];
  currentStep: T;
  setStep: (step: T) => void;
  noPreviousStepCallback?: () => void;
  previousStepDisabledCallback?: () => void;
}

export const handlePrevious = <T extends string>({
  steps,
  stepOrder,
  currentStep,
  setStep,
  noPreviousStepCallback,
  previousStepDisabledCallback,
}: HandlePreviousArgs<T>): void => {
  const currentStepIndex = stepOrder.indexOf(currentStep);
  const previousStepIndex = currentStepIndex - 1;
  const previousStepKey = stepOrder[previousStepIndex];

  if (previousStepIndex !== -1 && !steps[previousStepKey].disabled) {
    setStep(previousStepKey);
  } else if (previousStepIndex === -1 && noPreviousStepCallback) {
    noPreviousStepCallback();
  } else if (previousStepIndex !== -1 && steps[previousStepKey].disabled && previousStepDisabledCallback) {
    previousStepDisabledCallback();
  }
};

export const getStepConnectorColor = (status: StepStatus, followsAlert: boolean) => {
  if (status === StepStatus.INACTIVE || followsAlert) return 'neutral.400';
  return '$fg.system.success';
};
