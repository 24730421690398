import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  ChevronRightIcon,
  ErrorIcon,
  Heading,
  StepStatus,
  Text,
} from "@allica/ui-react";
import { Box, InputGroup, InputRightElement, useDisclosure } from "@chakra-ui/react";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormGroup } from "src/components/input-set/FormGroup";
import { BaseInput } from "src/components/input/default/Input";
import MaskedInput from "src/components/masked-input/MaskedInput";
import {
  BankDetailsValidationResponse,
  ConfirmationOfPayeeAPI,
  DepositsAPI,
  ValidationAPI,
} from "src/core/service";
import { ConfirmationOfPayeeResponse } from "src/core/service/confirmation-of-payee-api/ConfirmationOfPayeeApi.types";
import { ErrorResponse } from "src/core/service/Service.types";
import { concatenateName, emptyErrorDataHeapId } from "src/core/utils";
import { usePersonalContext } from "../../context/PersonalContext";
import { Stage } from "../../PersonalSavings.types";
import { getMatchType } from "../../../../shared/nominated-account/NominatedAccount.utils";
import { ConfirmationOfPayeeModal } from "../../../../components/confirmation-of-payee-modals";
import { FormValues, MatchType } from "../../../../shared/nominated-account/NominatedAccount.types";
import { statusCollection } from "src/core/service/Service.utils";
import { ValidBankConfirmationMessage } from "../../../../components/input";
import { Messages, validateSortCode } from "../../../../core/utils/validation/validation";

const NominatedAccount = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [matchType, setMatchType] = useState<MatchType>();
  const [copResponse, setCopResponse] = useState<ConfirmationOfPayeeResponse>();

  const {
    stepperConfig,
    setCurrentStage,
    setStepperConfig,
    personalSavingData,
    setPersonalSavingData,
    setShowGenericError,
  } = usePersonalContext();

  const { firstName, middleName, lastName } =
    personalSavingData?.individualApplicationSections?.aboutYouSection;

  const fullName = concatenateName({
    firstName,
    middleName,
    lastName,
  });

  const {
    handleSubmit,
    register,
    clearErrors,
    formState: { errors },
    getValues,
    setError,
    setValue,
  } = useForm<FormValues>({
    defaultValues: { ...personalSavingData?.individualApplicationSections?.accountSection },
  });

  const [accountNumber, setAccountNumber] = useState<string>(
    personalSavingData?.individualApplicationSections?.accountSection?.accountNumber || "",
  );
  const [isValidSortCode, setIsValidSortCode] = useState<boolean | undefined>(undefined);

  const {
    status: depositsStatus,
    request: depositsRequest,
    error: depositsError,
    setStatus: setDepositsStatus,
  } = DepositsAPI(`applications/individuals/${personalSavingData?.applicationID}/account`);

  const {
    status: payeeStatus,
    request: payeeRequest,
    response: payeeResponse,
    error: payeeError,
  } = ConfirmationOfPayeeAPI<ConfirmationOfPayeeResponse>("outbound/check");

  const {
    request: sortCodeCheckRequest,
    response: sortCodeCheckResponse,
    status: sortCodeCheckStatus,
  } = ValidationAPI<BankDetailsValidationResponse>("validate/bank-account");

  const handleRef = (e: HTMLInputElement | null) => {
    ref(e);
    maskedInputRef.current = e;
  };

  const onSubmit: SubmitHandler<FormValues> = ({ accountNumber, sortCode }) => {
    if (depositsStatus.error) {
      setDepositsStatus && setDepositsStatus(statusCollection.pending);
    }
    setShowGenericError(false);
    setCopResponse(undefined);
    payeeRequest({
      method: "POST",
      body: JSON.stringify({
        accountNumber,
        sortCode: sortCode.replace(/-/g, ""),
        name: fullName,
        payeeType: "PERSONAL",
      }),
    });
  };

  const onContinue = (errorResponse?: ErrorResponse<string>) => {
    const payload = { ...getValues(), name: fullName, copResponse: copResponse || errorResponse };
    payload.sortCode = payload.sortCode.replace(/-/g, "");
    depositsRequest({ method: "PATCH", body: JSON.stringify(payload) });
  };

  useEffect(() => {
    if (payeeStatus.success) {
      setMatchType(getMatchType(payeeResponse));
      setCopResponse(payeeResponse);
      onOpen();
    }

    if (payeeStatus.error) {
      // expected behaviour to continue on error from cop service
      const errorResponse = payeeError ? { ...payeeError, matched: false } : { matched: false };
      setCopResponse(errorResponse as ConfirmationOfPayeeResponse);
      onContinue(errorResponse);
    }
  }, [payeeStatus, matchType, payeeError]);

  useEffect(() => {
    if (depositsStatus.success && !!copResponse) {
      setPersonalSavingData({
        ...personalSavingData,
        individualApplicationSections: {
          ...personalSavingData.individualApplicationSections,
          accountSection: { ...getValues(), copResponse, name: fullName },
        },
      });

      const newStepperConfig = { ...stepperConfig };
      newStepperConfig[Stage.ACCOUNT].status = StepStatus.COMPLETE;
      if (newStepperConfig[Stage.DOCUMENT].status !== StepStatus.COMPLETE) {
        newStepperConfig[Stage.DOCUMENT].status = StepStatus.INCOMPLETE;
      }
      setStepperConfig(newStepperConfig);
      setCurrentStage(Stage.DOCUMENT);
    }

    if (depositsStatus.error) {
      if (depositsError.code === "VALIDATION_ERROR") {
        depositsError.errors?.forEach(({ field, reason }) => {
          if (field === "accountNumber" || field === "sortCode") {
            setError(field, { message: reason });
          }
        });
      } else {
        setShowGenericError(true);
      }
      onClose();
    }
  }, [depositsStatus, copResponse]);

  const handleSortCodeChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (value.length === 8) {
      clearErrors("sortCode");
      const noHyphensSortCode = value.replace(/-/gi, "");
      await sortCodeCheckRequest({ method: "GET", queryParams: { sortCode: noHyphensSortCode } });
    } else {
      setIsValidSortCode(false);
    }
    await onSortCodeChange(event);
  };

  const {
    onChange: onSortCodeChange,
    ref,
    ...restReg
  } = register("sortCode", {
    required: "Please enter a valid sort code",
    validate: validateSortCode(sortCodeCheckResponse.valid),
  });

  const maskedInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (maskedInputRef?.current?.value) {
      setValue("sortCode", maskedInputRef?.current?.value);
    }
  }, [maskedInputRef?.current?.value]);

  useEffect(() => {
    if (sortCodeCheckStatus.loading) {
      setIsValidSortCode(false);
      return;
    }

    if (sortCodeCheckStatus.error) {
      setIsValidSortCode(undefined);
      return;
    }

    if (sortCodeCheckResponse.valid) {
      clearErrors("sortCode");
      setIsValidSortCode(true);
      return;
    }

    if (sortCodeCheckResponse.valid === false) {
      setIsValidSortCode(false);
      setError("sortCode", { type: "manual", message: Messages.INVALID_SORT_CODE_TWO });
      return;
    }
  }, [sortCodeCheckResponse?.valid, sortCodeCheckStatus?.error, sortCodeCheckStatus.loading]);

  return (
    <>
      <Heading size="h1" as="h1" mb="1.6rem">
        Nominated account
      </Heading>
      <Text as="p" color="neutral.600" textStyle="body-02-regular">
        This is the account you will use to deposit money into your new Allica Bank account. It is
        also where we will send your money when you make a withdrawal.
      </Text>
      <br></br>
      <Text as="p" color="neutral.600" textStyle="body-02-bold">
        The account must be in your name and a UK current account.
      </Text>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormGroup
          label="Your name"
          isRequired
          mt="6.4rem"
          mb="3.2rem"
          info="To change go back to the About page"
        >
          <BaseInput value={fullName} isDisabled />
        </FormGroup>
        <FormGroup
          label="Sort code"
          mb="3.2rem"
          isRequired
          info="6 digits, e.g. 12-34-56"
          error={errors.sortCode?.message}
          data-heapid={emptyErrorDataHeapId(errors.sortCode?.type, "sort")}
        >
          <InputGroup width="23.8rem">
            <MaskedInput
              inputMode="numeric"
              maskedPattern="**-**-**"
              maskedChar="*"
              isInvalid={!!errors.sortCode}
              validate={(value: string) => /^\d+$/?.test(value)}
              onChangeHandler={handleSortCodeChange}
              data-heapid="sort-code-enter"
              {...restReg}
              ref={handleRef}
            />
            <InputRightElement>
              {!!errors?.sortCode && <ErrorIcon color="$fg.system.error_default" />}
            </InputRightElement>
          </InputGroup>
          {isValidSortCode && sortCodeCheckResponse?.sortCode?.bankName && (
            <ValidBankConfirmationMessage bankName={sortCodeCheckResponse.sortCode.bankName} />
          )}
        </FormGroup>
        <FormGroup
          label="Account Number"
          isRequired
          error={errors.accountNumber?.message}
          mb="4.8rem"
          data-heapid={emptyErrorDataHeapId(errors.accountNumber?.type, "account")}
        >
          <BaseInput
            type="text"
            inputMode="numeric"
            w="23.8rem"
            isInvalid={!!errors.accountNumber}
            value={accountNumber}
            data-heapid="account-number-enter"
            {...register("accountNumber", {
              maxLength: { value: 8, message: "Please enter a valid account number" },
              minLength: { value: 8, message: "Please enter a valid account number" },
              required: "Please enter a valid account number",
              setValueAs: (value: string) => {
                const regex = /^(\d{0,8})$/;
                if (regex.test(value)) {
                  setAccountNumber(value);
                  return value;
                }
                return accountNumber;
              },
            })}
          />
        </FormGroup>
        <Alert status="info" mb="8rem">
          <AlertIcon />
          <Box>
            <AlertTitle>Depositing money</AlertTitle>
            <AlertDescription>
              It is really important you use your nominated account to make a deposit, otherwise we
              will have to return your money to you.
            </AlertDescription>
          </Box>
        </Alert>
        <Button
          isLoading={payeeStatus.loading}
          loadingText="Save and continue"
          spinnerPlacement="end"
          type="submit"
          float="right"
          padding="2.4rem 3.2rem"
          rightIcon={<ChevronRightIcon boxSize="2.4rem" />}
          data-heapid="save-continue-button"
        >
          Save and continue
        </Button>
      </form>
      {Object.keys(payeeResponse).length !== 0 && (
        <ConfirmationOfPayeeModal
          copResponse={payeeResponse}
          onClose={onClose}
          isOpen={isOpen}
          accountDetails={{ ...getValues(), name: fullName }}
          continueAction={onContinue}
          continueActionLoading={depositsStatus.loading}
        />
      )}
    </>
  );
};

export default NominatedAccount;
