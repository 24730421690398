import { Dispatch, useEffect, useRef, SetStateAction } from "react";
import { List, ListItem } from "@chakra-ui/react";

interface FilteredSuggestionsProps {
  loading: boolean;
  filteredSuggestions: Record<string, unknown>[];
  handleClickSuggestion: (a: Record<string, unknown>) => void;
  highlightedItem: number;
  setHighLightedItem: Dispatch<SetStateAction<number>>;
  isMouseFocus: boolean;
  setIsMouseFocus: Dispatch<SetStateAction<boolean>>;
  dataHeapId?: string;
}

const FilteredSuggestions = ({
  loading,
  filteredSuggestions,
  handleClickSuggestion,
  highlightedItem,
  setHighLightedItem,
  isMouseFocus,
  setIsMouseFocus,
  dataHeapId,
}: FilteredSuggestionsProps) => {
  const listRef = useRef<HTMLUListElement>(null);

  const scrollToView = (pos: number) => {
    listRef.current?.scrollTo?.({
      top: pos,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (!isMouseFocus) {
      const lists = Array.from(listRef?.current?.children as unknown as []);
      scrollToView((lists[highlightedItem] as HTMLUListElement)?.offsetTop);
    }
  }, [highlightedItem]);

  return (
    <List
      boxShadow="md"
      overflow="auto"
      position="absolute"
      width="inherit"
      role="listbox"
      id="filter-list"
      maxH="33.6rem"
      mt="6.5rem"
      borderRadius="0.8rem"
      ref={listRef}
    >
      {loading ? (
        <ListItem
          p="1.6rem"
          h="5.6rem"
          role="option"
          bgColor="$bg.secondary"
          fontSize="2rem"
          lineHeight="2rem"
        >
          Loading...
        </ListItem>
      ) : filteredSuggestions?.length < 1 ? (
        <ListItem
          p="1.6rem"
          h="5.6rem"
          role="option"
          bgColor="$bg.secondary"
          fontSize="2rem"
          lineHeight="2rem"
        >
          No results found. Try searching again...
        </ListItem>
      ) : (
        filteredSuggestions?.map((item, index) => {
          return (
            <ListItem
              key={(item?.id || item?.label) as string}
              data-heapid={dataHeapId}
              bgColor="$bg.secondary"
              p="1.6rem"
              h="5.6rem"
              role="option"
              borderBottom="0.1rem solid"
              borderColor="$bg.primary"
              cursor="pointer"
              onClick={() => handleClickSuggestion(item)}
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
              fontSize="2rem"
              lineHeight="2rem"
              onMouseOver={() => {
                setIsMouseFocus(true);
                setHighLightedItem(index);
              }}
              sx={
                highlightedItem === index
                  ? {
                      backgroundColor: "$bg.input_controls.selected",
                      color: "$bg.secondary",
                    }
                  : undefined
              }
            >
              {item?.label as string}
            </ListItem>
          );
        })
      )}
    </List>
  );
};

export default FilteredSuggestions;
