import { useEffect, useState } from "react";
import {
  Alert,
  AlertAction,
  AlertActions,
  AlertDescription,
  AlertIcon,
  Button,
  ChevronRightIcon,
  FormControl,
  Heading,
  StepStatus,
  Text,
} from "@allica/ui-react";
import { Box, FormErrorMessage, Link } from "@chakra-ui/react";
import Checkbox from "src/components/checkbox/Checkbox";
import CompanyDetails from "./company-details/CompanyDetails";
import CompanyAddresses from "./company-addresses/CompanyAddresses";
import CompanyActivities from "./company-activities/CompanyActivities";
import CompanySearch from "./company-search/CompanySearch";
import { Controller, useFormContext } from "react-hook-form";
import TaxResidency from "src/components/tax-residency/TaxResidency";
import { CompanyFormValues, CompanyUIProps } from "./Company.types";
import { getBackendDataStructure, getUpdatedFrontendFields } from "./Company.utils";
import { useStore } from "src/core/store/StoreContext";

const CompanyUI = ({
  stepperConfig,
  setShowGenericError,
  companySectionData,
  saveReqStatus,
  saveData,
  saveError,
  applicationSource,
}: CompanyUIProps) => {
  const referenceData = useStore();
  const [searchCompanyPage, setSearchCompanyPage] = useState(
    stepperConfig.business.status !== StepStatus.COMPLETE,
  );

  const methods = useFormContext<CompanyFormValues>();
  const { setError, reset, formState, handleSubmit, control } = methods;

  useEffect(() => {
    setShowGenericError(false);
  }, [searchCompanyPage]);

  useEffect(() => {
    const newCompanyFormValues = getUpdatedFrontendFields(companySectionData, referenceData);
    reset(newCompanyFormValues);
  }, [companySectionData]);

  useEffect(() => {
    if (saveReqStatus.error) {
      if (saveError.code === "VALIDATION_ERROR") {
        const addressErrors: Record<string, string> = {};
        saveError?.errors?.forEach((data) => {
          const { field, reason } = data;
          if (field?.includes("taxIdentifications")) {
            if (field.includes("number")) {
              const num = field?.split(".")[0]?.replace(/taxIdentifications\[|\]/g, "");
              if (num && +num > 0) {
                setError(`taxResidencies.${+num - 1}.number`, { message: reason });
              }
            }
            if (field.includes("taxCountryCode")) {
              const num = field?.split(".")[0]?.replace(/taxIdentifications\[|\]/g, "");
              if (num && +num > 0) {
                setError(`taxResidencies.${+num - 1}.taxCountryCode`, { message: reason });
              }
            }
          } else if (field?.includes("addresses")) {
            const num = field?.split(".")[0]?.replace(/addresses\[|\]/g, "");

            if (addressErrors[`addresses.${+num}`]) {
              addressErrors[`addresses.${+num}`] += ", " + reason;
            } else {
              addressErrors[`addresses.${+num}`] = reason;
            }
          } else if (field?.includes("internationalTradingCountryIsoCodes")) {
            const num = field?.replace(/internationalTradingCountryIsoCodes\[|\]/g, "");
            if (num && +num > 0) {
              setError(`countries.${+num - 1}.value`, { message: reason });
            }
          } else {
            setError(field, { message: reason });
          }
        });

        for (const errors in addressErrors) {
          setError(`${errors}` as any, { message: addressErrors[errors] });
        }
      } else setShowGenericError(true);
    }
  }, [saveReqStatus.error]);

  const onSubmit = (data: CompanyFormValues) => {
    if (!searchCompanyPage) {
      const payload = getBackendDataStructure(data);
      saveData({
        method: "PATCH",
        body: JSON.stringify(payload),
      });
    }
  };

  return (
    <>
      <Heading size="h1" as="h1" mb="1.6rem">
        Business details
      </Heading>
      <Text as="p" mb="6.4rem" color="neutral.600">
        Your company must be registered with Companies House and be up to date in their system.
      </Text>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        {searchCompanyPage ? (
          <CompanySearch
            setSearchCompanyPage={setSearchCompanyPage}
            applicationSource={applicationSource}
          />
        ) : (
          <>
            <CompanyDetails setSearchCompanyPage={setSearchCompanyPage} />
            <TaxResidency
              mt="3.2rem"
              taxDescription={
                "Your company must be registered to pay tax in the UK to open this account. If your company is registered for tax in other jurisdictions, including the US, please add below."
              }
            />
            <CompanyAddresses />
            <CompanyActivities />
            <Alert status="info" mb="3.2rem">
              <AlertIcon />
              <Box>
                <AlertDescription>
                  All information held at Companies House must be accurate before applying. If it's
                  not accurate, please contact Companies House and update on their system.
                </AlertDescription>
                <AlertActions>
                  <AlertAction>
                    <Link
                      color="$fg.interactive.default"
                      textStyle="body-02-medium"
                      fontWeight="600"
                      textDecoration="none"
                      variant="plain"
                      target="_blank"
                      href="https://www.gov.uk/government/organisations/companies-house"
                    >
                      Go to Companies House
                    </Link>
                  </AlertAction>
                </AlertActions>
              </Box>
            </Alert>
            <FormControl mb="8rem" isInvalid={!!formState?.errors.confirmation}>
              {/* https://github.com/react-hook-form/react-hook-form/discussions/6838#discussioncomment-2093357 */}
              <Controller
                control={control}
                name="confirmation"
                render={({ field: { ref, ...rest } }) => (
                  <>
                    <Checkbox ref={ref} {...rest} data-heapid="confirmation-accurate-checkbox">
                      I confirm all information at Companies House is accurate
                    </Checkbox>
                    <FormErrorMessage mt="1.2rem" mb="2.4rm" data-heapid="confirmation-empty-error">
                      <>
                        {formState?.errors?.confirmation && formState?.errors?.confirmation.message}
                      </>
                    </FormErrorMessage>
                  </>
                )}
                rules={{
                  required: "Please confirm all information is accurate",
                }}
              />
            </FormControl>
          </>
        )}
        <Button
          isLoading={saveReqStatus.loading}
          loadingText="Save and continue"
          spinnerPlacement="end"
          type="submit"
          float="right"
          padding="2.4rem 3.2rem"
          rightIcon={<ChevronRightIcon boxSize="2.4rem" />}
          data-heapid="save-continue-button"
        >
          Save and continue
        </Button>
      </form>
    </>
  );
};

export default CompanyUI;
