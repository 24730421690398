import { Heading, Radio, RadioGroup, Text } from "@allica/ui-react";
import { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FormGroup } from "src/components/input-set/FormGroup";
import { FormValues, TaxResidencyProps } from "../AboutYou.types";
import { TaxResidencyCard } from "./TaxResidencyCard";

const TaxResidency = ({ taxStatus, setTaxStatus }: TaxResidencyProps) => {
  const { control, getValues, setError, clearErrors } = useFormContext<FormValues>();
  const { fields, append, remove } = useFieldArray({ name: "taxResidencies", control });
  const [changedCountry, setChangeCountry] = useState("");

  useEffect(() => {
    if (fields.length < 1) {
      setTaxStatus("0");
      append({ taxCountryCode: "", number: "" });
    }
  }, [fields]);

  const handleAddTaxResidency = (taxResidencyNumber: number) => {
    clearErrors(`taxResidencies.${taxResidencyNumber + 1}`);
    const currentCountryCode = getValues(`taxResidencies.${taxResidencyNumber}.taxCountryCode`);
    const currentTaxNumber = getValues(`taxResidencies.${taxResidencyNumber}.number`);

    if (currentCountryCode && currentTaxNumber) {
      append({ taxCountryCode: "", number: "" });
    }
    if (!currentCountryCode) {
      setError(`taxResidencies.${taxResidencyNumber}.taxCountryCode`, {
        message: "Please select a country from the list",
      });
    }
    if (!currentTaxNumber) {
      setError(`taxResidencies.${taxResidencyNumber}.number`, {
        message: "Please add a valid tax identification number",
      });
    }
  };

  const handleDeleteTaxInfo = (taxResidencyNumber: number) => {
    remove(taxResidencyNumber);
    setChangeCountry("");
  };

  const handleCountryChange = (e: Event) => {
    const { value } = e?.target as HTMLInputElement;
    setChangeCountry(value);
  };

  useEffect(() => {
    if (fields.length < 1) {
      setTaxStatus("0");
    }
  }, [fields]);

  return (
    <>
      <Heading size="h3" as="h2" mb="1.6rem" mt="8rem">
        Tax Status
      </Heading>
      <Text textStyle="body-02-regular" color="denim.900" mb="3.2rem">
        You must be a UK taxpayer to open this account. If you are a resident for tax in another
        jurisdictions, including the US, please add below.
      </Text>

      <FormGroup label="Are you a taxpayer outside the UK?" isRequired>
        <RadioGroup onChange={setTaxStatus} value={taxStatus}>
          <Radio value="0">No</Radio>
          <Radio value="1">Yes</Radio>
        </RadioGroup>
      </FormGroup>

      {taxStatus === "1" &&
        fields?.map((item, index) => (
          <TaxResidencyCard
            key={item.id}
            taxResidencyNumber={index}
            taxResidencies={fields}
            handleAddTaxResidency={handleAddTaxResidency}
            handleDeleteTaxInfo={handleDeleteTaxInfo}
            handleCountryChange={handleCountryChange}
            changedCountry={changedCountry}
          />
        ))}
    </>
  );
};
export default TaxResidency;
