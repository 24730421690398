import { Text } from "@allica/ui-react";
import { Box, Flex, useBreakpointValue } from "@chakra-ui/react";
import { StepIcon } from "../step-icon/step-icon";
import { StepProps, StepStatus } from "../types";

export const getStepTextColor = (status: StepStatus, disabled?: boolean) => {
  if (status === StepStatus.INACTIVE || disabled) return "$fg.disabled";
  return "$fg.secondary";
};

export const Step = ({
  setStep,
  status,
  disabled,
  label,
  isCurrentStep,
  disableConnector,
  ...rest
}: StepProps) => {
  const lessThanLg = useBreakpointValue({ base: true, lg: false });
  const notDisabledOrInactive = !disabled && StepStatus.INACTIVE !== status;
  const canActionBase = notDisabledOrInactive && lessThanLg;
  const canActionLg = notDisabledOrInactive && !isCurrentStep;

  const handleCallback = () => {
    if (canActionLg) setStep();
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" || e.keyCode === 13) handleCallback();
  };

  return (
    <Flex
      role="status"
      aria-live="polite"
      aria-atomic={false}
      aria-label={status === StepStatus.ALERT ? `Alert: ${label} requires attention` : ""}
      position="relative"
      top={disableConnector ? "0" : "-0.3rem"}
      align="center"
      {...rest}
    >
      <StepIcon status={status} />
      <Box
        as="a"
        role="link"
        userSelect="none"
        zIndex={1}
        h="2.4rem"
        boxSizing="border-box"
        borderBottom={canActionLg ? "0.1rem solid" : "none"}
        borderColor="$fg.secondary"
        onClick={handleCallback}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        aria-disabled={lessThanLg ? !canActionBase : !canActionLg}
        aria-current={isCurrentStep}
        cursor={canActionLg || canActionBase ? "pointer" : "auto"}
        _hover={{ border: "none" }}
      >
        <Text
          as="span"
          color={getStepTextColor(status, disabled)}
          textStyle={isCurrentStep ? "body-02-medium" : "body-02-regular"}
        >
          {label}
        </Text>
      </Box>
    </Flex>
  );
};
