import { ChangeEvent, forwardRef, useEffect, useMemo, useState } from "react";
import { Select, InputGroup, Flex, InputProps } from "@chakra-ui/react";
import { Text } from "@allica/ui-react";
import {
  joinPhoneNumber,
  removeLeadingZeros,
  sortDialingCodes,
  splitPhoneNumber,
} from "./utils/InternationalPhone.utils";

import { BaseInput } from "../default/Input";
import { useStore } from "src/core/store/StoreContext";

type InternationalPhoneInputProps = InputProps & { heapPrefix?: string };

const InternationalPhoneInput = forwardRef<HTMLInputElement, InternationalPhoneInputProps>(
  ({ value, onChange, w, heapPrefix = "", ...rest }, ref) => {
    const { phonePrefix } = useStore();

    const [number, setNumber] = useState("");
    const [selectedCountry, setSelectedCountry] = useState("+44");

    const phoneIntlCodes = useMemo(
      () => Array.from(phonePrefix).sort(sortDialingCodes),
      [phonePrefix],
    );

    useEffect(() => {
      const { countryCode: newCountryCode, phoneNumber: newNumber } = splitPhoneNumber(
        value?.toString() ?? "",
      );
      number !== newNumber && setNumber(newNumber);
      selectedCountry !== newCountryCode && setSelectedCountry(newCountryCode || "+44");
    }, [value]);

    const onValueChange = (evt: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      let { value } = evt.target;
      const { name } = evt.target;
      const isCountryField = name === "countryCode";
      let isFormatted = false;

      if (!isCountryField) {
        value = removeLeadingZeros(value.replace(/[^0-9]/g, "").replace(/(\..*)\./g, "$1"));

        if (value.startsWith("44")) {
          value = value.replace(/^44/, "");
          setSelectedCountry("+44");
          isFormatted = true;
        }

        setNumber(value);
      } else {
        setSelectedCountry(value);
      }

      const joinedNumber = isFormatted
        ? joinPhoneNumber("+44", value)
        : joinPhoneNumber(
            isCountryField ? value : selectedCountry,
            !isCountryField ? value : number,
          );
      const eventCopy = {
        ...evt,
        target: { ...evt.target, name: rest?.name ?? "", value: joinedNumber },
      } as ChangeEvent<HTMLInputElement>;

      onChange && onChange(eventCopy);
    };

    return (
      <InputGroup w={w ?? "100%"}>
        <Flex position="relative" w="20.58rem">
          <Text
            zIndex={1}
            w="calc(100% - 6.5rem)"
            ml="0.7rem"
            textAlign="center"
            alignSelf="center"
            position="absolute"
            bg="neutral.0"
            color="darkdenim.500"
            userSelect="none"
            textStyle="body-01-regular"
          >
            {selectedCountry}
          </Text>
          <Select
            id="countryCode"
            name="countryCode"
            mr="1.2rem"
            style={{ paddingRight: "4.6rem" }}
            value={selectedCountry}
            onChange={onValueChange}
            data-heapid={`${heapPrefix}country-code-select`}
          >
            {phoneIntlCodes.map((option, index) => (
              <option value={option.name} key={option.name + index}>
                {`${option.description} (${option.name})`}
              </option>
            ))}
          </Select>
        </Flex>

        <BaseInput
          name="phoneNumber"
          type="tel"
          value={number}
          minLength={9}
          maxLength={14}
          onChange={onValueChange}
          ref={ref}
          {...rest}
          data-heapid={`${heapPrefix}phone-number-enter`}
        />
      </InputGroup>
    );
  },
);

export default InternationalPhoneInput;
