import { Text } from "@allica/ui-react";
import { Box, Flex } from "@chakra-ui/react";
import { ConfirmIcon } from "src/components/icon";
import { Modal } from "src/components/modal/Modal";

interface MatchModalProps {
  name: string;
  sortCode: string;
  accountNumber: string;
  isOpen: boolean;
  onClose: () => void;
  primaryAction: () => void;
  primaryActionLoading: boolean;
}

export const MatchModal = ({
  name,
  sortCode,
  accountNumber,
  isOpen,
  onClose,
  primaryAction,
  primaryActionLoading,
}: MatchModalProps) => {
  return (
    <Modal
      closeOnOverlayClick={false}
      headerTitle="Account details match"
      primaryButtonText="Continue"
      primaryButtonLoading={primaryActionLoading}
      isOpen={isOpen}
      onClose={onClose}
      primaryAction={primaryAction}
      variant="success"
      heapId={{
        primaryAction: "continue-pass-button",
      }}
    >
      <Text as="p" textStyle="body-02-regular" color="denim.900" mb="4rem !important">
        The nominated account details you gave match the details on the account
      </Text>
      <Box>
        <Flex
          direction={{ base: "column", sm: "row" }}
          py="1.6rem"
          borderWidth="0.1rem 0"
          borderColor="$bg.primary"
        >
          <Text
            mb={{ base: "0.8rem", sm: "0" }}
            as="p"
            w="21.2rem"
            textStyle="support-01-regular"
            color="neutral.600"
          >
            Application for
          </Text>
          <Flex alignItems="center">
            <ConfirmIcon
              color="$fg.system.success"
              mr="0.4rem"
              h="1.6rem"
              w="1.6rem"
              fill="neutral.0"
            />
            <Text as="p" textStyle="body-02-medium" color="darkdenim.500">
              {name}
            </Text>
          </Flex>
        </Flex>
        <Flex
          direction={{ base: "column", sm: "row" }}
          py="1.6rem"
          borderWidth="0 0 0.1rem"
          borderColor="$bg.primary"
        >
          <Text
            mb={{ base: "0.8rem", sm: "0" }}
            as="p"
            w="21.2rem"
            textStyle="support-01-regular"
            color="neutral.600"
          >
            Sort code
          </Text>
          <Flex alignItems="center">
            <ConfirmIcon
              color="$fg.system.success"
              mr="0.4rem"
              h="1.6rem"
              w="1.6rem"
              fill="neutral.0"
            />
            <Text as="p" textStyle="body-02-medium" color="darkdenim.500">
              {sortCode}
            </Text>
          </Flex>
        </Flex>
        <Flex
          direction={{ base: "column", sm: "row" }}
          py="1.6rem"
          borderWidth="0 0 0.1rem"
          borderColor="$bg.primary"
        >
          <Text
            mb={{ base: "0.8rem", sm: "0" }}
            as="p"
            w="21.2rem"
            textStyle="support-01-regular"
            color="neutral.600"
          >
            Account number
          </Text>
          <Flex alignItems="center">
            <ConfirmIcon
              color="$fg.system.success"
              mr="0.4rem"
              h="1.6rem"
              w="1.6rem"
              fill="neutral.0"
            />
            <Text as="p" textStyle="body-02-medium" color="darkdenim.500">
              {accountNumber}
            </Text>
          </Flex>
        </Flex>
      </Box>
    </Modal>
  );
};
