import { ReactNode } from 'react';
import { BoxProps } from '@chakra-ui/react';

export interface Step extends BoxProps {
  label: string;
  disabled?: boolean;
  status: StepStatus;
}

export type Steps<T extends string = string> = { [key in T]: Step };

export enum StepStatus {
  COMPLETE = 'complete',
  INCOMPLETE = 'incomplete',
  INACTIVE = 'inactive',
  ALERT = 'alert',
}

export interface StepConnectorProps {
  color: string;
}

export interface StepIconProps {
  status: StepStatus;
}

export interface StepProps {
  setStep: () => void;
  disabled?: boolean;
  status: StepStatus;
  label: string;
  isCurrentStep: boolean;
  disableConnector?: boolean;
}

export interface StepListItemProps extends StepProps {
  followsAlertStep: boolean;
}

export interface StepListProps<T extends string> {
  currentStep: T;
  setStep: (step: T) => void;
  steps: Steps<T>;
  stepOrder: T[];
}

export interface StepperProps<T extends string> extends StepListProps<T> {
  eyebrowText: string;
  eyebrowContent?: ReactNode;
  bottomContent?: ReactNode;
  formTitle: string;
  previousStepDisabledCallback?: () => void;
  noPreviousStepCallback?: () => void;
  showPreviousStepButton?: boolean;
  backgroundColor?: string;
}

export interface MobileStepperProps<T extends string> extends StepperProps<T> {
  showBelow: 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  notificationAriaLabel: string;
}

export interface StepHeaderProps<T extends string> {
  steps: Steps<T>;
  stepOrder: T[];
  currentStep: T;
  notificationAriaLabel: string;
  onOpen: () => void;
  setStep: (step: T) => void;
  previousStepDisabledCallback?: () => void;
  noPreviousStepCallback?: () => void;
}

export interface MobileStepListItemProps<T extends string> {
  setStep: (step: T) => void;
  disabled?: boolean;
  status: StepStatus;
  label: string;
  stepKey: T;
  currentStep: T;
  onClose: () => void;
}

export interface MobileStepListProps<T extends string> {
  isOpen: boolean;
  onClose: () => void;
  eyebrowText: string;
  eyebrowContent?: ReactNode;
  bottomContent?: ReactNode;
  formTitle: string;
  steps: Steps<T>;
  stepOrder: T[];
  currentStep: T;
  setStep: (step: T) => void;
}
