import { Box, Flex } from "@chakra-ui/react";
import { StepHeaderProps, StepStatus } from "../../types";
import { handlePrevious } from "../../helpers";
import { NotificationBadge } from "../notification-badge/notification-badge";
import { ChevronLeftIcon, Heading, Text } from "@allica/ui-react";
import { EllipsesHorizontalIcon } from "src/components/icon";

export const StepHeader = <T extends string>({
  steps,
  currentStep,
  onOpen,
  setStep,
  stepOrder,
  noPreviousStepCallback,
  previousStepDisabledCallback,
  notificationAriaLabel,
}: StepHeaderProps<T>) => {
  const showAlertNotification = stepOrder.some(
    (stepKey) => steps[stepKey].status === StepStatus.ALERT,
  );

  const handleClick = () => {
    handlePrevious({
      steps,
      currentStep,
      setStep,
      stepOrder,
      previousStepDisabledCallback,
      noPreviousStepCallback,
    });
  };

  return (
    <Flex bgColor="$bg.secondary" h="10.8rem" justifyContent="space-between">
      <Flex>
        <Box
          aria-label="previous"
          as="button"
          p="1.2rem 1.6rem 1.2rem 0.8rem"
          mt="1.2rem"
          mb="4rem"
          onClick={handleClick}
        >
          <ChevronLeftIcon color="$fg.primary" boxSize="2.4rem" />
        </Box>
        <Flex direction="column" py="2.4rem">
          <Heading size="h2" color="$fg.primary" mt="0.4rem" mb="0.8rem">
            {steps[currentStep].label}
          </Heading>
          <Text as="p" textStyle="body-02-regular" color="$fg.system.success">
            Step
            <Text as="span" mx="0.4rem" textStyle="body-01-regular">
              {stepOrder.indexOf(currentStep) + 1}
            </Text>
            of
            <Text as="span" ml="0.4rem" textStyle="body-01-regular">
              {stepOrder.length}
            </Text>
          </Text>
        </Flex>
      </Flex>
      <Flex
        aria-label="open navigation menu"
        onClick={onOpen}
        as="button"
        m="1.8rem 1.8rem 4.6rem 0"
        p="0.6rem"
      >
        <EllipsesHorizontalIcon color="darkdenim.500" />
        {showAlertNotification && (
          <NotificationBadge
            ariaLabel={notificationAriaLabel}
            sx={{ position: "absolute", top: "2.4rem", right: "2.4rem" }}
          />
        )}
      </Flex>
    </Flex>
  );
};
