import { Box, Flex, GridItem, IconButton } from "@chakra-ui/react";
import {
  Button,
  Heading,
  SummaryListItem,
  SummaryListItemDescription,
  SummaryListItemTitle,
  Text,
  Toast,
  ToastDescription,
  ToastIcon,
  useToast,
} from "@allica/ui-react";
import { formatSortCode } from "../../../../../core/utils";
import { CopyOutlineIcon } from "../../../../../components/icon";
import { DepositsAPI } from "../../../../../core/service";
import { useEffect } from "react";
import { appInsights } from "../../../../../core/app/ApplicationInsights";
import environment from "../../../../../environments/environment";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { usePersonalContext } from "../../../context/PersonalContext";
import DepositSuccessOld from "../deposit-success-old/DepositSuccessOld";
import BrandLayout from "../../../../../components/brand-layout/BrandLayout";

interface RefreshTokenResponse {
  accessToken: string;
  expiresIn: number;
  refreshToken: string;
}

/**
 * Not RESPONSIVE!
 *
 * @remarks
 * - This component is not responsive and requires updating if ever being used in PROD
 * - Should also use rem instead of pixels
 */
export const DepositSuccess = () => {
  const { personalSavingData } = usePersonalContext();
  const toast = useToast();

  const accountDetails = personalSavingData?.individualApplicationSections?.outcome?.accountDetails;
  const nominatedAccount = personalSavingData?.individualApplicationSections.accountSection;

  const { status, request, response } = DepositsAPI<RefreshTokenResponse>(
    "applications/refresh-token",
  );

  useEffect(() => {
    if (status.success) {
      appInsights.trackEvent({ name: `online-banking-login-success` });
      sessionStorage.setItem("token", response.accessToken);
      sessionStorage.setItem("refreshToken", response.refreshToken);
      window.location.href = environment.ui.onlineBanking + "/accounts";
    }

    if (status.error) {
      appInsights.trackEvent({ name: `error-on-ping-refresh-token` });
      appInsights.trackException({
        exception: new Error("error-on-ping-refresh-token"),
        severityLevel: SeverityLevel.Error,
      });
      // Force login screen on error
      sessionStorage.clear();
      window.location.href = environment.ui.onlineBanking;
    }
  }, [status]);

  const handleGoToAccount = async () => {
    const refreshToken = sessionStorage.getItem("refreshToken");
    await request({
      method: "POST",
      body: JSON.stringify({ refreshToken }),
    });
  };

  const handleCopyClick = (value?: string) => async () => {
    if (!value) {
      throw Error("Could not copy value to clipboard");
    }

    await navigator.clipboard.writeText(value);
    toast({
      position: "bottom-left",
      render: () => (
        <Toast status="success">
          <ToastIcon />
          <ToastDescription>Copied to clipboard</ToastDescription>
        </Toast>
      ),
    });
  };

  if (!accountDetails || !nominatedAccount) {
    return <DepositSuccessOld />;
  }

  return (
    <BrandLayout>
      <GridItem
        colSpan={{ base: 4, sm: 6, md: 4, lg: 6 }}
        colStart={{ base: 1, sm: 2, md: 3, lg: 4 }}
      >
        <Box mb={{ base: "48px" }} mt={{ base: "64px" }}>
          <Heading size="h1" as="h1" color="neutral.0">
            Account opened!
          </Heading>
        </Box>
        <Box bg="#fff" borderRadius="4px" p="24px" mb="120px">
          <Text as="p" textStyle="body-03-regular" color="denim.900" mb="32px">
            You now have 14 days to deposit money into your account and start saving. To make the
            first deposit follow these 2 easy steps:
          </Text>
          <Heading as="h3" size="h3" color="darkdenim.500" mt="32px" mb="24px">
            Step 1 - Log into your bank
          </Heading>
          <Box bgColor="#F1F4F7" p="16px 32px" borderRadius="8px">
            Transfer the money from your nominated account{" "}
            <b>
              {formatSortCode(nominatedAccount.sortCode)} {nominatedAccount.accountNumber}
            </b>
            .
          </Box>
          <Heading as="h3" size="h3" color="darkdenim.500" mt="32px" mb="24px">
            Step 2 - Fund your new deposit account
          </Heading>
          <Box bgColor="#F1F4F7" p="16px 32px" borderRadius="8px">
            <SummaryListItem
              title={<SummaryListItemTitle>Payee name</SummaryListItemTitle>}
              description={
                <SummaryListItemDescription textVariant="body">
                  {accountDetails.name}
                </SummaryListItemDescription>
              }
            />
            <SummaryListItem
              title={<SummaryListItemTitle>Sort code</SummaryListItemTitle>}
              description={
                <SummaryListItemDescription textVariant="body" w="100%">
                  {accountDetails.sortCode && formatSortCode(accountDetails.sortCode)}
                </SummaryListItemDescription>
              }
              action={
                <IconButton
                  backgroundColor="transparent"
                  _hover={{ backgroundColor: "transparent" }}
                  _focus={{ backgroundColor: "transparent" }}
                  icon={<CopyOutlineIcon w="24px" h="24px" mt="4px" />}
                  aria-label="Copy sort code"
                  onClick={handleCopyClick(accountDetails.sortCode)}
                />
              }
            />
            <SummaryListItem
              title={<SummaryListItemTitle>Account number</SummaryListItemTitle>}
              description={
                <SummaryListItemDescription textVariant="body" w="100%">
                  {accountDetails.accountNumber}
                </SummaryListItemDescription>
              }
              action={
                <IconButton
                  backgroundColor="transparent"
                  _hover={{ backgroundColor: "transparent" }}
                  _focus={{ backgroundColor: "transparent" }}
                  icon={<CopyOutlineIcon w="24px" h="24px" mt="4px" />}
                  aria-label="Copy account number"
                  onClick={handleCopyClick(accountDetails.accountNumber)}
                />
              }
            />
            <SummaryListItem
              title={<SummaryListItemTitle>Reference</SummaryListItemTitle>}
              description={
                <SummaryListItemDescription textVariant="body" w="100%">
                  Choose your own reference
                </SummaryListItemDescription>
              }
            />
            <Box h="1px" w="100%" backgroundColor="#F1F4F7" mt="-1px" />
          </Box>
        </Box>
        <Flex justifyContent="flex-end">
          <Box>
            <Button onClick={handleGoToAccount}>Go to my account</Button>
          </Box>
        </Flex>
      </GridItem>
    </BrandLayout>
  );
};
