import { createIcon } from "@chakra-ui/react";

export const ConfirmFillIcon = createIcon({
  displayName: "ConfirmFillIcon",
  viewBox: "0 0 24 24",
  defaultProps: {
    h: "24px",
    w: "24px",
    fill: "white",
  },
  path: (
    <>
      <circle cx="12" cy="12" r="10.5" fill="currentColor" />
      <path d="M18 8.25L16.8 7L9.6 14.5L7.2 12L6 13.25L9.6 17L18 8.25Z" />
    </>
  ),
});
