import { Box, SystemStyleObject } from '@chakra-ui/react';

interface NotificationBadgeProps {
  sx?: SystemStyleObject;
  ariaLabel: string;
}

export const NotificationBadge = ({ sx, ariaLabel }: NotificationBadgeProps) => (
  <Box
    role="status"
    aria-label={ariaLabel}
    aria-live="polite"
    aria-atomic
    borderRadius="100%"
    h="0.6rem"
    w="0.6rem"
    backgroundColor="mango.400"
    sx={sx}
  />
);
