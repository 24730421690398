import { Text } from "@allica/ui-react";
import { Box, Flex } from "@chakra-ui/react";
import { ConfirmIcon, WarningIcon } from "src/components/icon";
import { Modal } from "src/components/modal/Modal";

interface PartialMatchModalProps {
  name: string;
  sortCode: string;
  accountNumber: string;
  isOpen: boolean;
  onClose: () => void;
  primaryAction: () => void;
  secondaryAction: () => void;
  suggestedFix?: string;
  wrongAccountType?: boolean;
  secondaryActionLoading: boolean;
  businessAccount?: boolean;
}

export const PartialMatchModal = ({
  name,
  sortCode,
  accountNumber,
  isOpen,
  onClose,
  primaryAction,
  secondaryAction,
  secondaryActionLoading,
  suggestedFix,
  wrongAccountType,
  businessAccount,
}: PartialMatchModalProps) => {
  return (
    <Modal
      headerTitle="Details don't match account"
      primaryButtonText="Update account details"
      secondaryButtonText="Continue without changing"
      isOpen={isOpen}
      onClose={onClose}
      primaryAction={primaryAction}
      secondaryAction={secondaryAction}
      secondaryButtonLoading={secondaryActionLoading}
      variant="alert"
      closeOnOverlayClick={false}
      heapId={{
        secondaryAction: "continue-nochange-button",
        primaryAction: "update-account-button",
      }}
    >
      <Text as="p" mb="4rem !important" textStyle="body-02-regular" color="denim.900">
        Please double check the nominated account details. If you continue with these details your
        application will be reviewed by our team.
      </Text>
      <Box>
        <Flex
          direction={{ base: "column", sm: "row" }}
          py="1.6rem"
          borderWidth="0.1rem 0"
          borderColor="$bg.primary"
        >
          <Text
            mb={{ base: "0.8rem", sm: "0" }}
            as="p"
            minW="21.2rem"
            textStyle="support-01-regular"
            color="neutral.600"
          >
            Application for
          </Text>
          <Flex direction="column">
            <Flex alignItems="center">
              <WarningIcon color="mango.600" h="1.6rem" w="1.6rem" mr="0.4rem" />
              <Text as="p" textStyle="body-02-medium" color="darkdenim.500">
                {name}
              </Text>
            </Flex>
            {wrongAccountType && (
              <Text
                mb={suggestedFix ? "0.8rem" : "0"}
                as="p"
                textStyle="support-01-regular"
                color="neutral.600"
              >
                {`Your nominated account says the account type is a ${
                  businessAccount ? "personal" : "business"
                } account. Please update to
                a ${businessAccount ? "business" : "personal"} account.`}
              </Text>
            )}
            {suggestedFix && (
              <Text as="p" textStyle="support-01-regular" color="neutral.600">
                {`The recipient’s bank says the name you entered isn’t right. Do you mean ${suggestedFix}?`}
              </Text>
            )}
          </Flex>
        </Flex>
        <Flex
          direction={{ base: "column", sm: "row" }}
          py="1.6rem"
          borderWidth="0 0 0.1rem"
          borderColor="$bg.primary"
        >
          <Text
            mb={{ base: "0.8rem", sm: "0" }}
            as="p"
            minW="21.2rem"
            textStyle="support-01-regular"
            color="neutral.600"
          >
            Sort code
          </Text>
          <Flex alignItems="center">
            <ConfirmIcon
              color="$fg.system.success"
              mr="0.4rem"
              h="1.6rem"
              w="1.6rem"
              fill="neutral.0"
            />
            <Text as="p" textStyle="body-02-medium" color="darkdenim.500">
              {sortCode}
            </Text>
          </Flex>
        </Flex>
        <Flex
          direction={{ base: "column", sm: "row" }}
          py="1.6rem"
          borderWidth="0 0 0.1rem"
          borderColor="$bg.primary"
        >
          <Text
            mb={{ base: "0.8rem", sm: "0" }}
            as="p"
            minW="21.2rem"
            textStyle="support-01-regular"
            color="neutral.600"
          >
            Account number
          </Text>
          <Flex alignItems="center">
            <ConfirmIcon
              color="$fg.system.success"
              mr="0.4rem"
              h="1.6rem"
              w="1.6rem"
              fill="neutral.0"
            />
            <Text as="p" textStyle="body-02-medium" color="darkdenim.500">
              {accountNumber}
            </Text>
          </Flex>
        </Flex>
      </Box>
    </Modal>
  );
};
